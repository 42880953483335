import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';

import WelcomeSummary from './WelcomeSummary';
import RevenueHistory from './RevenueHistory';
import NewCustomers from './NewCustomers';
import Growth from './Growth';
import ProjectCounterCard from './ProjectCounterCard';
import FilesCounterCard from './FilesCounterCard';
import TasksCounterCard from './TasksCounterCard';
import TeamsCounterCard from './TeamsCounterCard';
// import TasksList from './TasksList';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Dashboard', isActive: true },
];

const Dashboard = () => {
  return (
    <PageContainer heading={<IntlMessages id="pages.dashboard" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <WelcomeSummary />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <RevenueHistory />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NewCustomers />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Growth />
        </Grid>
        <GridContainer>
          <Grid item xs={12} sm={6} md={3}>
            <ProjectCounterCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TasksCounterCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TeamsCounterCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FilesCounterCard />
          </Grid>

          {/* <Grid item xs={12}>
              <TasksList />
            </Grid> */}
        </GridContainer>
      </GridContainer>
    </PageContainer>
  );
};

export default Dashboard;
