import React, { useContext, useEffect, useState } from 'react';
import { Box, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
// import { getConversation } from '../../../redux/actions/Chat';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ClientAwsUpdateMethods } from '../../../services/auth';
import { CurrentClientAwsUpdateMethods } from '../../../@jumbo/constants/AppConstants';
import { NavLink, useHistory } from 'react-router-dom';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import { useLocation, useParams } from 'react-router-dom';
import { idGenerator } from '@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

const EditAwsContainer = ({ FormData, method = CurrentClientAwsUpdateMethods }) => {
  console.log('FormData1223585', FormData);

  const dispatch = useDispatch();
  let scrollBarRef = null;
  const { id, _id } = useParams();
  console.log('iddddd....param', id);
  console.log('iddddd....param', _id);
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState(FormData.name);
  const [accessKey, setAccesskey] = useState(FormData.accessKey);
  const [secretKey, setSecretKey] = useState(FormData.secretKey);

  const [nameError, setNameError] = useState('');
  const [accessKeyError, setAccesskeyError] = useState('');
  const [secretKeyError, setSecretKeyError] = useState('');

  const createValidation = () => {
    //if (!Id) {
    //setIdError(requiredMessage);
    //} else
    if (!name) {
      setNameError(requiredMessage);
    } else if (!accessKey) {
      setAccesskeyError(requiredMessage);
    } else if (!secretKey) {
      setSecretKeyError(requiredMessage);
    } else return true;
  };

  const token = localStorage.getItem('token');

  const onSubmit = () => {
    if (createValidation()) {
      const data = {
        _id: _id,
        aws: {
          id: id,
          name: name,
          accessKey: accessKey,
          secretKey: secretKey,
        },
      };
      dispatch(ClientAwsUpdateMethods[method].OnAwsUpdateClient(token, data));
      history.push('/clients');
    }
  };

  return (
    <Box className="submain">
      {/* <ContentHeader FormDataToHeader={FormData} /> */}
      <h3>Update AWS</h3>
      <form className={classes.root} noValidate autoComplete="off">
        <GridContainer>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.appid"
                  label="Name"
                  onChange={event => {
                    setName(event.target.value);
                    setNameError('');
                  }}
                  helperText={nameError}
                  defaultValue={name}
                />
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.accesskey"
                  label="AccessKey"
                  onChange={event => {
                    setAccesskey(event.target.value);
                    setAccesskeyError('');
                  }}
                  helperText={accessKeyError}
                  defaultValue={accessKey}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.secretKey"
                  label="SecretKey"
                  onChange={event => {
                    setSecretKey(event.target.value);
                    setSecretKeyError('');
                  }}
                  helperText={secretKeyError}
                  defaultValue={secretKey}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <Box>
                <Button onClick={onSubmit} variant="contained" className={classes.button} color="primary">
                  {' '}
                  Update{' '}
                </Button>
                <NavLink className={classes.navLink} to={'/clients'}>
                  <Button variant="contained" className={classes.button} color="secondary">
                    {' '}
                    Cancel{' '}
                  </Button>
                </NavLink>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
        <div />
        <div />
      </form>
    </Box>
  );
};

export default withWidth()(EditAwsContainer);
