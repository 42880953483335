import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import { useParams, NavLink } from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
//import JSONSchemaForm from 'react-jsonschema-form';
import 'jsoneditor-react/es/editor.min.css';
//import JSONInput from 'react-json-editor-ajrm';
//import locale from 'react-json-editor-ajrm/locale/en';
import { useDispatch, useSelector } from 'react-redux';
import { TenantConfigMethods } from '../../../../services/auth';
import { CurrentTenantConfigMethod } from '../../../../@jumbo/constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ConfigData(props, { method = CurrentTenantConfigMethod }) {
  const classes = useStyles();
  const tenantParams = useParams(); //const { id, type, tenantType} = useParams();
  const dispatch = useDispatch();
  const jsonEditorRef = useRef(null)
  const setEditorRef = instance => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };
  const [isEditorInitiate, setIsEditorInitiate] = useState(false);
  const [isView, setIsView] = useState(true);
  const tenantConfigData1 = useSelector(state => state.auth.getTenantConfig);
  const [tenantConfigData, setTenantConfigData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(TenantConfigMethods[method].onTenantConfig(tenantParams.id, token));
  }, []);

  if(tenantConfigData1 && tenantConfigData1.length> 0){
    if(isEditorInitiate==false){
      setIsEditorInitiate(true);
      //delete tenantConfigData1[0]._id;
      //delete tenantConfigData1[0].tenantId;
      //delete tenantConfigData1[0].env;
      setTenantConfigData(tenantConfigData1[0]);
      if (jsonEditorRef.current !== null) {
        jsonEditorRef.current.set(tenantConfigData1[0]);
      }
    }
  }
  /*let tenantConfigData1=[];
  tenantConfigData1=tenantConfigData;
  if(tenantConfigData1.length> 0 && tenantConfigData1._id){
    delete tenantConfigData1._id;
  }
  const [configDatas, setConfigDatas] = useState(tenantConfigData1);*/
  //const FormJST = JSONSchemaForm.default;
  //console.log('final.......', props);
  const configChange = item => {
    setTenantConfigData(item);
  };
  const schema = {
    title: 'My title',
    description: 'My description',
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      age: {
        type: 'number',
      },
    },
  };

  return (
    <Box className={classes.root}>
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                {/* Name */}
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                Config
                {/* <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.name}</Box>
                <Box><Editor value={configDatas} onChange={configChange} /></Box>
                <JSONSchemaForm schema={schema} /> 
                <JSONInput id='configeditor' placeholder={tenantConfigData} locale={locale} width={""}  />*/}
                <Editor ref={setEditorRef} value={tenantConfigData1} onChange={configChange} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
}
