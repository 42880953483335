import React from 'react';
import { Box } from '@material-ui/core';
import Sidebar from './Sidebar';
import useStyles from './index.style';
import ChatContainer from './ChatContainer';
import EditChatContainer from './EditChatContainer';
import { useLocation } from 'react-router-dom';

const TaskList = () => {
  const location = useLocation();
  console.log('location', location);
  const classes = useStyles();

  if (location.pathname === '/users') {
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer />
      </Box>
    );
  } else if (location.pathname === '/users/id') {
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <EditChatContainer />
      </Box>
    );
  }
};

export default TaskList;
