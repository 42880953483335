import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { TransferservicesUpdateMethods, TransferLookupListMethod } from '../../../../services/auth';
import {
  CurrentTransferservicesUpdateMethods,
  CurrentTransferLookupMethod,
} from '../../../../@jumbo/constants/AppConstants';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export default function BasicTextFields({ FormData, method = CurrentTransferservicesUpdateMethods }) {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState(FormData.name);
  const [type, setType] = useState(FormData.type);
  const [image, setImage] = useState(FormData.image);
  const [secretKey, setSecretKey] = useState(FormData.secretKey);
  const [accessKey, setAccessKey] = useState(FormData.accessKey);
  const [region, setRegion] = useState(FormData.region);

  const [nameError, setnameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [secretKeyError, setSecretKeyError] = useState('');
  const [accessKeyError, setAccessKeyError] = useState('');
  const [regionError, setRegionError] = useState('');

  console.log('Form Data 2', FormData);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const updateValidation = () => {
    if (!name) {
      setnameError(requiredMessage);
      // } else if (!type) {
      //   setTypeError(requiredMessage);
    } else if (!secretKey) {
      setSecretKeyError(requiredMessage);
    } else if (!accessKey) {
      setAccessKeyError(requiredMessage);
    } else if (!region) {
      setRegionError(requiredMessage);
    } else return true;
  };

  const onSubmit = () => {
    if (updateValidation()) {
      const data = {
        _id: FormData._id,
        name: name,
        type: type,
        image: image,
        secretKey: secretKey,
        accessKey: accessKey,
        region: region,
      };
      dispatch(TransferservicesUpdateMethods[method].OnTransferServicesUpdate(token, data));
      history.push('/transferservices/' + FormData._id + '/isView');
    }
  };

  const transferLookupData = useSelector(state => state.auth.getTransferLookupList);

  useEffect(() => {
    dispatch(TransferLookupListMethod[CurrentTransferLookupMethod].onTransferLookupList(token));
  }, []);
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                className=""
                id="appModule.name"
                label="Name"
                onChange={event => {
                  setName(event.target.value);
                  setnameError('');
                }}
                helperText={nameError}
                defaultValue={name}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="type-native-simple">Type</InputLabel>
              <Select
                native
                value={type}
                onChange={event => {
                  setType(event.target.value);
                  setTypeError('');
                }}
                helperText={typeError}
                inputProps={{
                  name: 'type',
                  id: 'type-native-simple',
                }}>
                <option aria-label="None" value="" />
                {transferLookupData &&
                  transferLookupData.length > 0 &&
                  transferLookupData.map(tld => <option value={tld}>{tld}</option>)}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                id="appModule.image"
                label="Image"
                onChange={event => setImage(event.target.value)}
                defaultValue={image}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Button onClick={onSubmit} variant="contained" className={classes.button} color="primary">
                {' '}
                Submit{' '}
              </Button>
              <NavLink className={classes.navLink} to={'/transferservices/' + FormData._id + '/isView'}>
                <Button variant="contained" className={classes.button} color="secondary">
                  {' '}
                  Cancel{' '}
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                className=""
                id="appModule.access"
                label="Access Key"
                onChange={event => {
                  setAccessKey(event.target.value);
                  setAccessKeyError('');
                }}
                helperText={accessKeyError}
                defaultValue={accessKey}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                className=""
                id="appModule.secret"
                label="Secret Key"
                onChange={event => {
                  setSecretKey(event.target.value);
                  setSecretKeyError('');
                }}
                helperText={secretKeyError}
                defaultValue={secretKey}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                className=""
                id="appModule.region"
                label="Region"
                onChange={event => {
                  setRegion(event.target.value);
                  setRegionError('');
                }}
                helperText={regionError}
                defaultValue={region}
              />
            </FormControl>
          </Box>
        </Grid>
      </GridContainer>
    </form>
  );
}
