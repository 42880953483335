import React, { useContext, useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { useParams, NavLink } from 'react-router-dom';
import CmtCard from '../../../../@coremat/CmtCard';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TenantMethods } from '../../../../services/auth';
import { CurrentTenantMethod } from '../../../../@jumbo/constants/AppConstants';

const columns = [
  { id: 'tenantId', label: 'Tenant ID' },
  { id: 'name', label: 'App Name' },
  // {
  //   id: 'dbtype',
  //   label: 'DB Type',
  //   minWidth: 170,
  // },
  // {
  //   id: 'dbname',
  //   label: 'DB Name',
  //   minWidth: 170,
  // }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));*/

export default function TenantsTabContent(props, { tenantMethod = CurrentTenantMethod }) {
  const classes = useStyles();
  console.log('final.......123', props);
  const { id, type, tenantType } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [tenantId, setTenantId] = React.useState('1000001');
  // const [env, setEnv] = React.useState('');
  // const [authentication, setAuthentication] = React.useState('');
  // const [provider, setProvider] = React.useState('');
  // const [cogBlock, setCogBlock] = React.useState(false);
  // const [provideBlock, setProvideBlock] = React.useState(false);
  const [addClient, setAddClient] = useState(false);
  const [name, setName] = useState('');

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');
  const [itemId, setItemId] = useState('');

  const [nameError, setNameError] = useState('');

  const tenantData = useSelector(state => state.auth.getTenantList);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const ttid = props.DatafromCentered.id;
    dispatch(TenantMethods[tenantMethod].onTenantList(ttid, token));
  }, []);

  const createValidation = () => {
    if (!name) {
      setNameError(requiredMessage);
    } else return true;
  };

  // const handleDeleteClick = (item, itemId) => {
  //   setItem(item);
  //   setItemId(itemId);
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOk = (id, _id) => {
    //   setOpen(false);
    //   const data = {
    //     _id: _id,
    //     aws: {
    //       id: id,
    //     },
    //   };
    //   dispatch(AwsDeleteMethod[method2].onAwsDelete(data));
    //   // history.push('/clients');
  };

  const handleAddClickOpen = () => {
    // setData({});
    setAddClient(true);
  };

  const handleEditClickOpen = () => {
    // if (data && data.id) {
    //   setData(data);
    // }
    // setAddClient(true);
  };
  const addClientClose = () => {
    setAddClient(false);
  };

  const submitAddClient = () => {
    // if (createValidation()) {
    //     const data = {
    //       _id: FormData._id,
    //       aws: {
    //         name: name,
    //         accessKey: accessKey,
    //         secretKey: secretKey,
    //       },
    //     };
    //     dispatch(ClientAwsCreateMethods[method].OnAwsCreateClient(data));
    //     setAddClient(false);
    //     //   history.push('/clients');
    //   }
    // }
  };

  let passId = props && props.DatafromCentered && props.DatafromCentered._id ? props.DatafromCentered._id : id;

  // const ClientTenant = useSelector(
  //   state => state.auth && state.auth.getclientList && state.auth.getclientList.length > 0 && state.auth.getclientList,
  // );

  // let TenantObj =
  //   ClientTenant && Array.isArray(ClientTenant) && ClientTenant.length > 0 && ClientTenant.find(element => element._id == passId);

  // let TenantArray = TenantObj && TenantObj.tenant && Array.isArray(TenantObj.tenant) && TenantObj.tenant.length > 0 ? TenantObj.tenant : [];

  return (
    <Box>
      <Grid>
        <CmtCard className={useStyles.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box>
              <Typography component="div" variant="h4">
                Tenant List
              </Typography>
            </Box>
            <Box className={classes.clientActions}>
              {/* <NavLink className={classes.navLink} to={'/clients/' + passId + '/tenant/create'}> */}
              <IconButton color="primary" aria-label="Add" className={classes.margin} onClick={handleAddClickOpen}>
                <AddBox />
              </IconButton>
              {/* </NavLink> */}
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell width="15%" align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            {tenantData && tenantData.length > 0 ? (
              <TableBody>
                {tenantData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <NavLink className={classes.navLink} to={'/clients/' + row.tenantId + '/isTenant'}>
                          <IconButton aria-label="view" className={classes.margin}>
                            <VisibilityIcon />
                          </IconButton>
                        </NavLink>
                        <IconButton aria-label="delete" className={classes.margin}>
                          <Delete />
                        </IconButton>
                        {/* <IconButton aria-label="delete" className={classes.margin} onClick={handleOpenTenantSettings}>
                        <SettingsIcon></SettingsIcon>
                      </IconButton> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              'No Data Found'
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tenantData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          onClose={addClientClose}
          aria-labelledby="customized-dialog-title"
          open={addClient}>
          <DialogTitle id="customized-dialog-title" onClose={addClientClose}>
            Add Tenant
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setNameError('');
                      }}
                      helperText={nameError}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addClientClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddClient()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleOk(item, itemId)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
