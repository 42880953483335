import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ConfigData from './ConfigData';
import Destinations from './Destinations';
import Storages from './Storages';
import TransferServices from './TransferServices';
// import TenantsTabContent from './TenantsTabContent';
// import AwsContent from './AwsContent';
// import IconikContent from './IconikContent';
// import AsperaContent from './AsperaContent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log('props****tab***', props);
  return (
    <Box className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab label="Config" {...a11yProps(0)} />
          <Tab label="Destinations" {...a11yProps(1)} />
          <Tab label="Storages" {...a11yProps(2)} />
          <Tab label="Transfer Services" {...a11yProps(3)} />
          {/* <Tab label="Tenants" {...a11yProps(4)} /> */}
          {/* <Tab label="Item Six" {...a11yProps(5)} />
          <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <GeneralInfoTabContent DatafromCentered={props.FormDataToCentered} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AwsContent DatafromCentered={props.FormDataToCentered} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <IconikContent DatafromCentered={props.FormDataToCentered} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AsperaContent DatafromCentered={props.FormDataToCentered} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TenantsTabContent DatafromCentered={props.FormDataToCentered} />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <ConfigData />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Destinations />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Storages /> 
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TransferServices />
      </TabPanel>
    </Box>
  );
}
