import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthUser,
  setErrorAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
  setMainMenusList,
  setDestinationLookupList,
  setTransferLookupList,
  setClientList,
  setClientViewData,
  setClientCreateData,
  setClientUpdateData,
  setClientDeleteData,
  setAwsDeleteData,
  setClientCreateAwsData,
  setIconikCreateData,
  setClientUpdateAwsData,
  setTenantList,
  setTenantConfig,
  setDestinationList,
  setDestinationViewData,
  setDestinationCreateData,
  setDestinationUpdateData,
  setDestinationDeleteData,
  setStoragesList,
  setStoragesViewData,
  setStoragesCreateData,
  setStoragesUpdateData,
  setStoragesDeleteData,
  setTransferServicesList,
  setTransferServicesViewData,
  setTransferservicesCreateData,
  setTransferservicesUpdateData,
  setTransferservicesDeleteData,
  setAsperaCreateData,
  setIconikUpdateData,
  setIconikDeleteData,
  setAsperaUpdateData,
  setAsperaDeleteData,
} from '../../../redux/actions/Auth';
import React from 'react';
import axios from 'axios';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  // login //

  onLogin: ({ email, password }) => {
    // console.log('emil', email, password);
    // const data = {"emailid": email, "password": password };
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          const data = { emailid: email, password: password };
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          // console.log('user', data);

          axios
            .post('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/authenticate/signin?', data, {
              type: 'application/json',
            })
            .then(response => {
              // console.log('data...responce', response);
              return response.data.result;
            })
            .then(data => {
              // console.log('111', data);
              localStorage.setItem('token', data.token);
              localStorage.setItem('emailid', data.emailid);
              localStorage.setItem('name', data.name);
              localStorage.setItem('status', data.status);
              localStorage.setItem('userType', data.userType);
              localStorage.setItem('userid', data.userid);
              dispatch(setAuthUser(data));
            })
            .catch(function(error) {
              // console.log('catch...error', error.response);
              if (error.response && error.response.status == 500) {
                // console.log("1234")
                let errorMsg = error.response;
                dispatch(setErrorAuthUser(errorMsg));
              }
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // AppMenus //

  onMainMenus: token => {
    //// console.log('*****', token);
    // const data = {"emailid": email, "password": password };
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('!!!', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/appmenus', {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response.data);
              return response.data;
            })
            .then(data => {
              // // console.log('111222', data);
              dispatch(setMainMenusList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Destination lookup //

  onDestinationLookupList: token => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          axios
            .get('https://f91xsqd3l3.execute-api.us-east-1.amazonaws.com/latest/lookups/destination')
            .then(response => {
              // console.log('data...responce..lookup 1', response.data);
              return response.data.result[0].values;
            })
            .then(data => {
              // // console.log('111222', data);
              dispatch(setDestinationLookupList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Transfer lookup //

  onTransferLookupList: token => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          axios
            .get('https://f91xsqd3l3.execute-api.us-east-1.amazonaws.com/latest/lookups/transferservices')
            .then(response => {
              // console.log('data...responce..lookup 1', response.data);
              return response.data.result[0].values;
            })
            .then(data => {
              // // console.log('111222', data);
              dispatch(setTransferLookupList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // ClientList //
  onClientList: token => {
    // console.log('*****', token);
    // const data = {"emailid": email, "password": password };
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          //// console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients', {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              //console.log('data...client..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              //// console.log('111222', data);
              dispatch(setClientList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  ////// single-Client ////////
  onClientViewData: id => {
    // console.log('*****', id);
    const token = localStorage.getItem('token');
    // const data = {id};
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/' + id, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              console.log('111222', data);
              dispatch(setClientViewData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Client Update //

  OnClientUpdate: (token, ClientUpdateData) => {
    console.log('client update', ClientUpdateData);
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients', ClientUpdateData, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              console.log('data...responce', response);
              return response.data.result[0];
            })
            .then(data => {
              if (ClientUpdateData._id) {
                ClientUpdateData['status'] = 'active';
                dispatch(setClientUpdateData(ClientUpdateData));
              } else {
                dispatch(setClientCreateData(data));
              }
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete Client ////////
  onClientDelete: id => {
    console.log('Delete Client', id);
    const token = localStorage.getItem('token');
    // const data = {id};
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .delete('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/' + id, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              console.log('111222', id);
              dispatch(setClientDeleteData([id]));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete AWS ////////
  onAwsDelete: AwsDeleteData => {
    // console.log('Delete Client', AwsDeleteData);
    const token = localStorage.getItem('token');
    // const data = {id};
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          console.log('Delete Client......', AwsDeleteData);
          console.log('Delete token', token);
          // console.log('client-token', token);
          axios
            .delete('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
              data: AwsDeleteData,
            })
            .then(response => {
              console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              console.log('111222', data);
              dispatch(setAwsDeleteData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete ICONIK ////////
  onIconikDelete: IconikDeleteData => {
    // console.log('Delete Client', IconikDeleteData);
    const token = localStorage.getItem('token');
    // const data = {id};
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // // console.log('client-token', token);
          axios
            .delete('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
              data: IconikDeleteData,
            })
            .then(response => {
              // console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setIconikDeleteData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////////////Create AWS Cient ///////////////////

  OnAwsCreateClient: ClientAwsData => {
    // console.log('client update', ClientAwsData);
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', ClientAwsData, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setClientCreateAwsData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////////////Create Iconik Cient ///////////////////

  OnIconikCreate: iconikData => {
    // console.log('client update', iconikData);
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', iconikData, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setIconikCreateData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////////////Update Iconik Cient ///////////////////

  OnIconikUpdate: iconikUpdate => {
    // console.log('client update', iconikUpdate);
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', iconikUpdate, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setIconikUpdateData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////////////Update Aspera Cient ///////////////////

  OnAsperaUpdate: asperaUpdate => {
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', asperaUpdate, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setAsperaUpdateData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete ASPERA ////////

  onIconikDelete: asperaDeleteData => {
    const token = localStorage.getItem('token');
    // const data = {id};
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // // console.log('client-token', token);
          axios
            .delete('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
              data: asperaDeleteData,
            })
            .then(response => {
              // console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              dispatch(setAsperaDeleteData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////////////Create Aspera Cient ///////////////////

  OnAsperaCreate: asperaData => {
    // console.log('client update', asperaData);
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', asperaData, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setAsperaCreateData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // TenantList //
  onTenantList: (clientId, token) => {
    // console.log('*****', token);
    // const data = {"emailid": email, "password": password };
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          //// console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/tenants?cid=' + clientId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              //console.log('data...client..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              //// console.log('111222', data);
              dispatch(setTenantList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // onTenantConfig //
  onTenantConfig: (tenantId, token) => {
    // console.log('*****', token);
    // const data = {"emailid": email, "password": password };
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          //// console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/tenant/config?tenantId=' + tenantId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              //console.log('data...client..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              //// console.log('111222', data);
              dispatch(setTenantConfig(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  /////////// AWS Update /////////////////

  OnAwsUpdateClient: ClientAwsData => {
    // console.log('client update........', ClientAwsData);
    const token = localStorage.getItem('token');
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/clients/services/', ClientAwsData, {
              type: 'application/json',
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...responce', response);
              return response;
            })
            .then(data => {
              dispatch(setClientUpdateAwsData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  ///////// DestinationList ///////////

  onDestinationList: (token, tenantId) => {
    // console.log('*****', token);
    // const data = {"emailid": email, "password": password };
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/destinations?tenantId=' + tenantId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...Destination..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setDestinationList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  ///////// Destination single View ///////////

  onDestinationViewData: (id, tenantId) => {
    // console.log('*****', id);
    const token = localStorage.getItem('token');
    // const data = {"emailid": email, "password": password };
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/destinations/' + id + '?tenantId=' + tenantId,
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...Destination..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setDestinationViewData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Destination Update //

  OnDestinationUpdate: (token, DestinationUpdateData, tenantId) => {
    // console.log('Destination update', DestinationUpdateData);
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/destinations?tenantId=' + tenantId,
              DestinationUpdateData,
              {
                type: 'application/json',
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...responce', response);
              return response.data.result[0];
            })
            .then(data => {
              if (DestinationUpdateData._id) {
                DestinationUpdateData['status'] = 'active';
                dispatch(setDestinationUpdateData(DestinationUpdateData));
              } else {
                dispatch(setDestinationCreateData(data));
              }
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete Destination ////////
  onDestinationDelete: (id, tenantId) => {
    // console.log('Delete Destination', id);
    const token = localStorage.getItem('token');
    // const data = {id};
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // // console.log('client-token', token);
          axios
            .delete(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/destinations/' + id + '?tenantId=' + tenantId,
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setDestinationDeleteData([id]));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  /////////// Storages List /////////////

  onStoragesList: (token, tenantId) => {
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/storages?tenantId=' + tenantId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...storages..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setStoragesList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  ///////// storages single View ///////////

  onStoragesViewData: (id, tenantId) => {
    // console.log('*****', id);
    const token = localStorage.getItem('token');
    // const data = {"emailid": email, "password": password };
    tenantId = tenantId || '';

    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/storages/' + id + '?tenantId=' + tenantId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...storages..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setStoragesViewData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Storages Update //

  OnStoragesUpdate: (token, StorageUpdateData, tenantId) => {
    console.log('OnStoragesUpdate update', StorageUpdateData);
    console.log('client update', tenantId);
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/storages?tenantId=' + tenantId,
              StorageUpdateData,
              {
                type: 'application/json',
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...responce', response);
              return response.data.result[0];
            })
            .then(data => {
              if (StorageUpdateData._id) {
                StorageUpdateData['status'] = 'active';
                dispatch(setStoragesUpdateData(StorageUpdateData));
              } else {
                dispatch(setStoragesCreateData(data));
              }
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete Storages ////////
  onStoragesDelete: (id, tenantId) => {
    const token = localStorage.getItem('token');
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // // console.log('client-token', token);
          axios
            .delete(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/storages/' + id + '?tenantId=' + tenantId,
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setStoragesDeleteData([id]));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  /////////// Tansfer Services List /////////////

  onTransferServicesList: (token, tenantId) => {
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get('https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/transferservices?tenantId=' + tenantId, {
              headers: {
                Authorization: token,
              },
            })
            .then(response => {
              // console.log('data...storages..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setTransferServicesList(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  ///////// Tansfer Services single View ///////////

  onTransferServicesViewData: (id, tenantId) => {
    // console.log('*****', id);
    const token = localStorage.getItem('token');
    // const data = {"emailid": email, "password": password };
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // console.log('client-token', token);
          axios
            .get(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/transferservices/' +
                id +
                '?tenantId=' +
                tenantId,
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...storages..responce', response.data.result);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setTransferServicesViewData(data));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Transfer Services Update //

  OnTransferServicesUpdate: (token, TransferUpdateData, tenantId) => {
    // console.log('client update', TransferUpdateData);
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // localStorage.setItem('user', JSON.stringify(data));
          //// console.log('user', data);

          axios
            .put(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/transferservices?tenantId=' + tenantId,
              TransferUpdateData,
              {
                type: 'application/json',
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...responce', response);
              return response.data.result[0];
            })
            .then(data => {
              if (TransferUpdateData._id) {
                TransferUpdateData['status'] = 'active';
                dispatch(setTransferservicesUpdateData(TransferUpdateData));
              } else {
                dispatch(setTransferservicesCreateData(data));
              }
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //////  Delete Transferservices ////////
  onTransferServicesDelete: (id, tenantId) => {
    const token = localStorage.getItem('token');
    tenantId = tenantId || '';
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          // // console.log('client-token', token);
          axios
            .delete(
              'https://ophog9cb79.execute-api.us-east-1.amazonaws.com/latest/transferservices/' +
                id +
                '?tenantId=' +
                tenantId,
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              // console.log('data...client..responce', response.data);
              return response.data.result;
            })
            .then(data => {
              // console.log('111222', data);
              dispatch(setTransferservicesDeleteData([id]));
            });
        }, 3000);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  /////////////Logout ///////////
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
      }, 300);
    };
  },

  onForgotPassword: email => {
    // console.log('onForgotPassword', email);
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(email));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
