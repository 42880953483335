import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SocialProfiles() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                FaceBook
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">rajeshkanna.176@gmail.com</Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Twitter
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">rajeshkanna.176@gmail.com</Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Instagram
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">rajeshkanna.176@gmail.com</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Youtube
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">rajeshkanna.176@gmail.com</Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
}
