import React, { useContext, useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import { useParams, NavLink } from 'react-router-dom';
import CmtCard from '../../../../@coremat/CmtCard';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { ClientAwsCreateMethods, ClientAwsUpdateMethods, AwsDeleteMethod } from '../../../../services/auth';
import {
  CurrentClientAwsCreateMethods,
  CurrentClientAwsUpdateMethods,
  CurrentAwsDeleteMethod,
} from '../../../../@jumbo/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const columns = [
  // { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'name', label: 'Name' },
  /*{
    id: 'accessKey',
    label: 'AccessKey',
    minWidth: 170,
  },
  {
    id: 'secretKey',
    label: 'SecretKey',
    minWidth: 170,
  },*/
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));*/

export default function AwsContent(
  props,
  { method = CurrentClientAwsCreateMethods, method1 = CurrentClientAwsUpdateMethods, method2 = CurrentAwsDeleteMethod },
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log('final.......123', props);
  const { id, type, tenantType } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addClient, setAddClient] = useState(false);
  const [viewClient, setViewClient] = useState(false);
  const [data, setData] = useState({});

  const [name, setName] = useState('');
  const [accessKey, setAccesskey] = useState('');
  const [secretKey, setSecretKey] = useState('');

  const [nameError, setNameError] = useState('');
  const [accessKeyError, setAccesskeyError] = useState('');
  const [secretKeyError, setSecretKeyError] = useState('');

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');
  const [itemId, setItemId] = useState('');

  const createValidation = () => {
    let name = data ? data.name : name;
    let accessKey = data ? data.accessKey : accessKey;
    let secretKey = data ? data.secretKey : secretKey;

    if (!name) {
      setNameError(requiredMessage);
    } else if (!accessKey) {
      setAccesskeyError(requiredMessage);
    } else if (!secretKey) {
      setSecretKeyError(requiredMessage);
    } else return true;
  };

  const setDataUpdate = (key, dataValue, data) => {
    data[key] = dataValue;
    setData(data);
  };

  const handleDeleteClick = (item, itemId) => {
    setItem(item);
    setItemId(itemId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOk = (id, _id) => {
    setOpen(false);
    const data = {
      _id: _id,
      aws: {
        id: id,
      },
    };
    dispatch(AwsDeleteMethod[method2].onAwsDelete(data));
    // history.push('/clients');
  };

  const handleAddClickOpen = () => {
    setData({});
    setAddClient(true);
  };

  const handleEditClickOpen = data => {
    if (data && data.id) {
      setData(data);
    }
    setAddClient(true);
  };
  const addClientClose = () => {
    setAddClient(false);
  };
  const handleViewClickOpen = data => {
    if (data && data.id) {
      setData(data);
    }
    setViewClient(true);
  };
  const viewClientClose = () => {
    setViewClient(false);
  };

  const submitAddClient = (_id, updateData) => {
    if (createValidation()) {
      if (updateData && updateData.id) {
        const data = {
          _id: _id,
          aws: {
            id: id,
            name: name,
            accessKey: accessKey,
            secretKey: secretKey,
          },
        };
        dispatch(ClientAwsUpdateMethods[method1].OnAwsUpdateClient(data));
        setAddClient(false);
      } else {
        const data = {
          _id: _id,
          aws: {
            name: name,
            accessKey: accessKey,
            secretKey: secretKey,
          },
        };
        dispatch(ClientAwsCreateMethods[method].OnAwsCreateClient(data));
        setAddClient(false);
        //   history.push('/clients');
      }
    }
  };

  let passId = props && props.DatafromCentered && props.DatafromCentered._id ? props.DatafromCentered._id : id;
  let AwsArray = props && props.DatafromCentered && props.DatafromCentered.aws ? props.DatafromCentered.aws : [];

  // console.log('here', passId);
  return (
    <Box>
      <Grid>
        <CmtCard className={useStyles.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box>
              <Typography component="div" variant="h4">
                AWS List
              </Typography>
            </Box>
            <Box className={classes.clientActions}>
              {/* <NavLink className={classes.navLink} to={'/clients/' + passId + '/aws/create'}> */}
              <IconButton color="primary" aria-label="Add" className={classes.margin} onClick={handleAddClickOpen}>
                <AddBox />
              </IconButton>
              {/* </NavLink> */}
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell width="20%" align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            {AwsArray && AwsArray.length > 0 ? (
              <TableBody>
                {AwsArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(Aws => {
                  // console.log("Aws",Aws)
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={Aws.code}>
                      {columns.map(column => {
                        // console.log("column",column)
                        const value = Aws[column.id];
                        // console.log("value",value)
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <IconButton
                          aria-label="edit"
                          className={classes.margin}
                          onClick={e => {
                            handleEditClickOpen(Aws);
                          }}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          className={classes.margin}
                          onClick={e => {
                            handleViewClickOpen(Aws);
                          }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(Aws.id, passId)}>
                          <Delete aria-label="delete" className={classes.margin} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              'No Data Found'
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={AwsArray.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addClientClose}
          aria-labelledby="customized-dialog-title"
          open={addClient}>
          <DialogTitle id="customized-dialog-title" onClose={addClientClose}>
            {data && data.id ? 'Edit Aws' : 'Add Aws'}
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.appid"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setNameError('');
                        setDataUpdate('name', event.target.value, data);
                      }}
                      helperText={nameError}
                      defaultValue={data ? data.name : name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.accesskey"
                      label="AccessKey"
                      onChange={event => {
                        setAccesskey(event.target.value);
                        setAccesskeyError('');
                        setDataUpdate('accessKey', event.target.value, data);
                      }}
                      helperText={accessKeyError}
                      defaultValue={data ? data.accessKey : accessKey}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.secretKey"
                      label="SecretKey"
                      onChange={event => {
                        setSecretKey(event.target.value);
                        setSecretKeyError('');
                        setDataUpdate('secretKey', event.target.value, data);
                      }}
                      helperText={secretKeyError}
                      defaultValue={data ? data.secretKey : secretKey}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addClientClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddClient(passId, data)} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleOk(item, itemId)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={viewClientClose}
          aria-labelledby="customized-dialog-title"
          open={viewClient}>
          <DialogTitle id="customized-dialog-title" onClose={viewClientClose}>
            View AWS
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Name
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.name : name}</Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Access Key
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.accessKey : accessKey}</Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Secret Key
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.secretKey : secretKey}</Box>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={viewClientClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
