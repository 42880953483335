import React, { useContext, useEffect } from 'react';
import { Box, withWidth } from '@material-ui/core';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
// import { getConversation } from '../../../redux/actions/Chat';
import Conversation from './Conversation';
import ContentHeader from './ContentHeader';
import Typography from '@material-ui/core/Typography';
import CmtImage from '../../../@coremat/CmtImage';
import ChatFooter from './ChatFooter';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getChatContainerHeight } from '../../../@jumbo/constants/AppConstants';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import CenteredTabs from './tabs/CenteredTabs';

const ChatContainer = props => {
  // const { showFooter } = useContext(AppContext);
  // const classes = useStyles({
  //   height: getChatContainerHeight(width, showFooter),
  // });
  const dispatch = useDispatch();
  const StoragesData = useSelector(state => state.auth.getstoragesList);
  let scrollBarRef = null;

  // const { currentUser, conversation } = useSelector(({ chat }) => chat);
  // useEffect(() => {
  //   if (currentUser) dispatch(getConversation(currentUser.channelId));
  // }, [currentUser]);

  // useEffect(() => {
  //   if (scrollBarRef) scrollBarRef._container.scrollTop = 99999;
  // }, [conversation]);

  // const loadPrevious = () => {
  //   // eslint-disable-next-line no-console
  //   console.log('loadPrevious trigger');
  // };

  // if (!currentUser) {
  return StoragesData && StoragesData.length > 0 ? (
    <Box className="submain">
      <ContentHeader FormDataToHeader={props.FormData} />
      <CenteredTabs FormDataToCentered={props.FormData} />
      {/* <Box mb={2}>
            <CmtImage src={'/images/chat_bubble.png'} />
          </Box>
          <Typography >Welcome to Jumbo Chat App</Typography> */}
    </Box>
  ) : null;
  // }
  {
    /* return (
    <Box >
       <ContentHeader user={currentUser} />
      <PerfectScrollbar
        onScrollY={container => {
          if (container.scrollTop === 0) {
            loadPrevious();
          }
        }}
        
        ref={ref => {
          scrollBarRef = ref;
        }}>
        <Conversation conversation={conversation} selectedUser={currentUser} />
      </PerfectScrollbar>
      <ChatFooter currentUser={currentUser} /> 
    </Box>
  );*/
  }
};

export default withWidth()(ChatContainer);
