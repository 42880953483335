import React, { useContext, useEffect, useState } from 'react';
import { Box, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
// import { getConversation } from '../../../redux/actions/Chat';
import Conversation from './Conversation';
import ContentHeader from './ContentHeader';
import Typography from '@material-ui/core/Typography';
import CmtImage from '../../../@coremat/CmtImage';
import ChatFooter from './ChatFooter';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getChatContainerHeight } from '../../../@jumbo/constants/AppConstants';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import EditCenteredTabs from './EditTabs/EditCenteredTabs';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { NavLink, useHistory } from 'react-router-dom';

import TenantCenteredTab from './tabs/TenantCenteredTabs';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

const CreateTenantContainer = ({ FormData }) => {
  const dispatch = useDispatch();
  let scrollBarRef = null;

  const classes = useStyles();
  const history = useHistory();
  const [tenantId, setTenantId] = useState('');
  const [name, setName] = useState('');
  const [clientId, setClientId] = useState('');
  const [dbType, setDbType] = useState('');
  const [dbName, setDbName] = useState('');
  const [minPoolSize, setMinPoolSize] = useState('');
  const [maxPoolSize, setMaxPoolSize] = useState('');
  const [maxIdleTimeMS, setMaxIdleTimeMS] = useState('');
  const [useNewUrlParser, setUseNewUrlParser] = useState('true');
  const [ssl, setSSL] = useState('true');
  const [sslValidate, setSSLValidate] = useState('false');
  const [retryWrites, setRetryWrites] = useState('false');
  const [useUnifiedTopology, setUseUnifiedTopology] = useState('true');
  const token = localStorage.getItem('token');
  const onSubmit = () => {
    // const data = {
    //   _id: FormData._id,
    //   name: name,
    //email: email,
    //address: address,
    //phone: phone,
    // };
    //dispatch(ClientUpdateMethods[method].OnClientUpdate(token, data));
    //history.push('/clients/' + FormData._id + '/isView');
  };

  return (
    <Box className="submain">
      {/* <ContentHeader FormDataToHeader={FormData} /> */}
      <TenantCenteredTab />
    </Box>
  );
};

export default withWidth()(CreateTenantContainer);
