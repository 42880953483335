import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { ClientUpdateMethods } from '../../../../services/auth';
import { CurrentClientUpdateMethods } from '../../../../@jumbo/constants/AppConstants';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';

// const currencies = [
//   {
//     value: 'True',
//     label: 'true',
//   },
//   {
//     value: 'False',
//     label: 'false',
//   },
// ];

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export default function BasicTextFields({ FormData, method = CurrentClientUpdateMethods }) {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState(FormData.name);
  const [email, setEmail] = useState(FormData.email);
  const [address, setAddress] = useState(FormData.address);
  const [phone, setPhone] = useState(FormData.phone);

  const [nameError, setnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const updateValidation = () => {
    if (!name) {
      setnameError(requiredMessage);
    } else if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    } else if (!address) {
      setAddressError(requiredMessage);
    } else if (!phone) {
      setPhoneError(requiredMessage);
    } else return true;
  };

  const onSubmit = () => {
    if (updateValidation()) {
      const data = {
        _id: FormData._id,
        name: name,
        email: email,
        address: address,
        phone: phone,
      };
      dispatch(ClientUpdateMethods[method].OnClientUpdate(token, data));
      history.push('/clients/' + FormData._id + '/isView');
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                className=""
                id="appModule.name"
                label="Name"
                onChange={event => {
                  setName(event.target.value);
                  setnameError('');
                }}
                helperText={nameError}
                defaultValue={name}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                id="appModule.email"
                label="Email"
                onChange={event => {
                  setEmail(event.target.value);
                  setEmailError('');
                }}
                helperText={emailError}
                defaultValue={email}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Button onClick={onSubmit} variant="contained" className={classes.button} color="primary">
                {' '}
                Submit{' '}
              </Button>
              <NavLink className={classes.navLink} to={'/clients/' + FormData._id + '/isView'}>
                <Button variant="contained" className={classes.button} color="secondary">
                  {' '}
                  Cancel{' '}
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                id="appModule.address"
                label="Address"
                onChange={event => {
                  setAddress(event.target.value);
                  setAddressError('');
                }}
                helperText={addressError}
                defaultValue={address}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField
                id="appModule.phone"
                label="Phone Number"
                onChange={event => {
                  setPhone(event.target.value);
                  setPhoneError('');
                }}
                helperText={phoneError}
                defaultValue={phone}
              />
            </FormControl>
          </Box>
        </Grid>
      </GridContainer>
      <div />
      <div />
    </form>
  );
}
