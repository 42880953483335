import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCard from '../../../../@coremat/CmtCard';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TransferservicesUpdateMethods, TransferLookupListMethod, TransferServicesMethods } from '../../../../services/auth';
import {
  CurrentTransferservicesUpdateMethods,
  CurrentTransferLookupMethod,
  CurrentTransferServicesMethod,
} from '../../../../@jumbo/constants/AppConstants';
const columns = [
  // { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
  },
  { id: 'region', label: 'Region', minWidth: 100 },
];
function createData(name, type, region) {
  return { name, type, region };
}
const rows = [
  createData('Sanchan Transfer Service', 'S3', 'us-east-1'),
  createData('Test  Transfer Service', 'S3', 'us-east-2'),
  createData('Sample  Transfer Service', 'S3', 'us-west-1'),
];
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TransferServices(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, pageType } = useParams();
  const [addTransferService, setAddTransferService] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [accessKey, setAccessKey] = useState('');
  const [region, setRegion] = useState('');

  const [nameError, setnameError] = useState('');
  const [typeError, setTypeError] = useState();
  const [secretKeyError, setSecretKeyError] = useState('');
  const [accessKeyError, setAccessKeyError] = useState('');
  const [regionError, setRegionError] = useState('');

  const handleAddClickOpen = () => {
    setAddTransferService(true);
  };
  const addTransferServiceClose = () => {
    setAddTransferService(false);
  };
  const token = localStorage.getItem('token');

  const submitAddTransferService = () => {
    //if (createValidation()) {
    const data = {
      name: name,
      type: type,
      image: image,
      secretKey: secretKey,
      accessKey: accessKey,
      region: region,
    };
    dispatch(TransferservicesUpdateMethods[CurrentTransferservicesUpdateMethods].OnTransferServicesUpdate(token, data, id));
    setAddTransferService(false);
    //history.push('/transferservices');
    //}
  };

  const transferServicesData = useSelector(
    state =>
      state.auth &&
      state.auth.gettransferservicesList &&
      state.auth.gettransferservicesList.length > 0 &&
      state.auth.gettransferservicesList,
  );

  const transferLookupData = useSelector(state => state.auth.getTransferLookupList);

  useEffect(() => {
    dispatch(TransferServicesMethods[CurrentTransferServicesMethod].onTransferServicesList(token, id));
    dispatch(TransferLookupListMethod[CurrentTransferLookupMethod].onTransferLookupList(token));
  }, []);

  let transferServicesDataList = transferServicesData && transferServicesData.length > 0 ? transferServicesData : [];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box>
      <Grid>
        <CmtCard className={useStyles.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box ml={{ xs: 5, xl: 8 }}>
              <Typography component="div" variant="h4">
                TransferServices
              </Typography>
            </Box>
            <Box className={classes.clientActions}>
              <IconButton aria-label="Add" className={classes.margin} onClick={handleAddClickOpen}>
                <AddBox />
              </IconButton>
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {transferServicesDataList && transferServicesDataList.length > 0 ? (
              <TableBody>
                {transferServicesDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IconButton aria-label="edit" className={classes.margin}>
                          <Edit />
                        </IconButton>
                        <IconButton>
                          <Delete aria-label="delete" className={classes.margin} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              'No Data Found'
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addTransferServiceClose}
          aria-labelledby="customized-dialog-title"
          open={addTransferService}>
          <DialogTitle id="customized-dialog-title" onClose={addTransferServiceClose}>
            Add Transfer Service
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setnameError('');
                      }}
                      helperText={nameError}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                    <Select
                      native
                      value={type}
                      onChange={event => {
                        setType(event.target.value);
                        setTypeError();
                      }}
                      helperText={typeError}
                      inputProps={{
                        name: 'type',
                        id: 'type-native-simple',
                      }}>
                      <option aria-label="None" value="" />
                      {transferLookupData &&
                        transferLookupData.length > 0 &&
                        transferLookupData.map(tld => <option value={tld}>{tld}</option>)}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="appModule.image"
                      label="Image"
                      onChange={event => setImage(event.target.value)}
                      defaultValue={image}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.secret"
                      label="Secret Key"
                      onChange={event => {
                        setSecretKey(event.target.value);
                        setSecretKeyError('');
                      }}
                      helperText={secretKeyError}
                      defaultValue={secretKey}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.access"
                      label="Access Key"
                      onChange={event => {
                        setAccessKey(event.target.value);
                        setAccessKeyError('');
                      }}
                      helperText={accessKeyError}
                      defaultValue={accessKey}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.region"
                      label="Region"
                      onChange={event => {
                        setRegion(event.target.value);
                        setRegionError('');
                      }}
                      helperText={regionError}
                      defaultValue={region}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addTransferServiceClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddTransferService()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
