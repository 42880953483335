import React from 'react';
import { Box } from '@material-ui/core';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import useStyles from './index.style';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import { onUserSelect } from '../../../redux/actions/Chat';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import UserListHeader from './UserListHeader';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const ContentHeader = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onOptionItemClick = item => {
    console.log('item', item);
    if (item === 'edit') {
      // dispatch(onUserSelect(null));
    } else if (item === 'delete') {
      // dispatch(onUserSelect(null));
    }
  };
  return (
    <Box className={classes.appContentHeader}>
      {/* <IconButton className={classes.backBtn} onClick={() => dispatch(onUserSelect(null))}> */}
      {/* <IconButton className={classes.backBtn}>
        <KeyboardBackspaceIcon />
      </IconButton> */}
      <Grid>
        <UserListHeader />
      </Grid>
      <Box className={classes.clientActions}>
        <NavLink className={classes.navLink} to="/users/isDelete">
          <IconButton aria-label="delete" className={classes.margin} onClick={() => onOptionItemClick('delete')}>
            <DeleteIcon />
          </IconButton>
        </NavLink>
        <NavLink className={classes.navLink} to="/users/id">
          <IconButton aria-label="edit" className={classes.margin} onClick={() => onOptionItemClick('edit')}>
            <EditIcon />
          </IconButton>
        </NavLink>
      </Box>
    </Box>
  );
};

export default ContentHeader;
