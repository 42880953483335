import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  GET_MENUS_LIST,
  GET_DESTINATION_LOOKUP,
  GET_TRANSFER_LOOKUP,
  GET_CLIENT_LIST,
  SET_CLIENT_VIEW_DATA,
  SET_CLIENT_CREATE_DATA,
  SET_CLIENT_UPDATE_DATA,
  SET_CLIENT_DELETE_DATA,
  GET_TENANT_LIST,
  GET_TENANT_CONFIG,
  GET_DESTINATION_LIST,
  SET_DESTINATION_VIEW_DATA,
  SET_DESTINATION_CREATE_DATA,
  SET_DESTINATION_UPDATE_DATA,
  SET_DESTINATION_DELETE_DATA,
  GET_STORAGES_LIST,
  SET_STORAGES_VIEW_DATA,
  SET_STORAGES_CREATE_DATA,
  SET_STORAGES_UPDATE_DATA,
  SET_STORAGES_DELETE_DATA,
  GET_TRANSFER_SERVICES_LIST,
  SET_TRANSFER_SERVICES_VIEW_DATA,
  SET_TRANSFER_SERVICES_CREATE_DATA,
  SET_TRANSFER_SERVICES_UPDATE_DATA,
  SET_TRANSFER_SERVICES_DELETE_DATA,
  ERROR_AUTH_USER,
  SET_CLIENT_AWS_CREATE_DATA,
  SET_CLIENT_AWS_UPDATE_DATA,
  SET_AWS_DELETE_DATA,
  SET_ICONIK_CREATE_DATA,
  SET_ASPERA_CREATE_DATA,
  SET_ICONIK_UPDATE_DATA,
  SET_ICONIK_DELETE_DATA,
  SET_ASPERA_DELETE_DATA,
  SET_ASPERA_UPDATE_DATA,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  getmenus: null,
  getDestinationLookupList: null,
  getTransferLookupList: null,
  getclientList: null,
  getTenantList: [],
  getTenantConfig: [],
  loadUser: false,
  send_forget_password_email: false,
  getclientViewData: null,
  getclientUpdateData: null,
  getdestinationList: null,
  getdestinationViewData: null,
  getdestinationUpdateData: null,
  getstoragesList: null,
  getstoragesViewData: null,
  gettransferservicesList: null,
  gettransferservicesViewData: null,
  getstoragesUpdateData: null,
  errorAuthUser: null,
  getclientAwsCreate: null,
  getclientAwsUpdate: null,
  getAwsDeleteData: null,
  getAsperaCreate: null,
  getIconikDeleteData: null,
  getAsperaDeleteData: null,
  getAsperaUpdate: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case ERROR_AUTH_USER: {
      return {
        ...state,
        errorAuthUser: action.payload,
        loadUser: true,
      };
    }
    case GET_MENUS_LIST: {
      return {
        ...state,
        getmenus: action.payload,
        loadUser: true,
      };
    }

    case GET_DESTINATION_LOOKUP: {
      return {
        ...state,
        getDestinationLookupList: action.payload,
        loadUser: true,
      };
    }

    case GET_TRANSFER_LOOKUP: {
      return {
        ...state,
        getTransferLookupList: action.payload,
        loadUser: true,
      };
    }

    case GET_CLIENT_LIST: {
      return {
        ...state,
        getclientList: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_VIEW_DATA: {
      return {
        ...state,
        getclientViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_ICONIK_CREATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getIconikCreate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_ASPERA_CREATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getAsperaCreate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_ICONIK_UPDATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getIconikUpdate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_ASPERA_UPDATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getAsperaUpdate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_AWS_CREATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getclientAwsCreate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_AWS_UPDATE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getclientAwsUpdate: action.payload,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_CREATE_DATA: {
      let updatedList = state.getclientList;
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getclientList: updatedList,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_UPDATE_DATA: {
      return {
        ...state,
        getclientList: state.getclientList.map(item => (item._id === action.payload._id ? action.payload : item)),
        //getclientUpdateData: action.payload,
        getclientViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_CLIENT_DELETE_DATA: {
      let updatedList = state.getclientList;
      updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        getclientList: updatedList,
        //getclientUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_AWS_DELETE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        // getClientList: updatedList,
        getAwsDeleteData: action.payload,
        loadUser: true,
      };
    }

    case SET_ICONIK_DELETE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        // getClientList: updatedList,
        getIconikDeleteData: action.payload,
        loadUser: true,
      };
    }

    case SET_ASPERA_DELETE_DATA: {
      // let updatedList = state.getclientList;
      // updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        // getClientList: updatedList,
        getAsperaDeleteData: action.payload,
        loadUser: true,
      };
    }

    case GET_TENANT_LIST: {
      return {
        ...state,
        getTenantList: action.payload,
        loadUser: true,
      };
    }

    case GET_TENANT_CONFIG: {
      return {
        ...state,
        getTenantConfig: action.payload,
        loadUser: true,
      };
    }

    case GET_DESTINATION_LIST: {
      return {
        ...state,
        getdestinationList: action.payload,
        loadUser: true,
      };
    }

    case SET_DESTINATION_VIEW_DATA: {
      return {
        ...state,
        getdestinationViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_DESTINATION_CREATE_DATA: {
      let updatedList = state.getdestinationList;
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getdestinationList: updatedList,
        //getdestinationUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_DESTINATION_UPDATE_DATA: {
      return {
        ...state,
        getdestinationList: state.getdestinationList.map(item => (item._id === action.payload._id ? action.payload : item)),
        //getdestinationUpdateData: action.payload,
        getdestinationViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_DESTINATION_DELETE_DATA: {
      let updatedList = state.getdestinationList;
      updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        getdestinationList: updatedList,
        //getdestinationUpdateData: action.payload,
        loadUser: true,
      };
    }

    case GET_STORAGES_LIST: {
      return {
        ...state,
        getstoragesList: action.payload,
        loadUser: true,
      };
    }

    case SET_STORAGES_VIEW_DATA: {
      return {
        ...state,
        getstoragesViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_STORAGES_CREATE_DATA: {
      let updatedList = state.getstoragesList;
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        getstoragesList: updatedList,
        //getstoragesUpdateData: action.payload,
        loadUser: true,
      };
    }

    case SET_STORAGES_UPDATE_DATA: {
      return {
        ...state,
        getstoragesList: state.getstoragesList.map(item => (item._id === action.payload._id ? action.payload : item)),
        //getstoragesUpdateData: action.payload,
        getstoragesViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_STORAGES_DELETE_DATA: {
      let updatedList = state.getstoragesList;
      updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        getstoragesList: updatedList,
        //getstoragesUpdateData: action.payload,
        loadUser: true,
      };
    }

    case GET_TRANSFER_SERVICES_LIST: {
      return {
        ...state,
        gettransferservicesList: action.payload,
        loadUser: true,
      };
    }

    case SET_TRANSFER_SERVICES_VIEW_DATA: {
      return {
        ...state,
        gettransferservicesViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_TRANSFER_SERVICES_CREATE_DATA: {
      let updatedList = state.gettransferservicesList;
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        gettransferservicesList: updatedList,
        loadUser: true,
      };
    }

    case SET_TRANSFER_SERVICES_UPDATE_DATA: {
      return {
        ...state,
        gettransferservicesList: state.gettransferservicesList.map(item =>
          item._id === action.payload._id ? action.payload : item,
        ),
        gettransferservicesViewData: action.payload,
        loadUser: true,
      };
    }

    case SET_TRANSFER_SERVICES_DELETE_DATA: {
      let updatedList = state.gettransferservicesList;
      updatedList = updatedList.filter(item => !action.payload.includes(item._id));
      return {
        ...state,
        gettransferservicesList: updatedList,
        loadUser: true,
      };
    }

    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    default:
      return state;
  }
};
