import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export default function EditUserDevices() {
  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   age: '',
  //   name: 'hai',
  // });

  // const handleChange = event => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField className="" id="standard-basic" label="Devices" />
            </FormControl>
          </Box>
        </Grid>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box>
            <Button variant="contained" className={classes.button} color="primary">
              {' '}
              Submit{' '}
            </Button>
            <Button variant="contained" className={classes.button} color="secondary">
              {' '}
              Cancel{' '}
            </Button>
          </Box>
        </Box>
      </GridContainer>
      <div />
      <div />
    </form>
  );
}
