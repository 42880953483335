import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  loginErrorMgs: {
    color: '#FF0000',
  },
  authBanner: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
    padding: 20,
    width: '50%',
    backgroundColor: '#141c29',
  },
  loginLink: {
    color: '#0e09ee',
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.text.primary, 0.2),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const signInObj = useSelector(state => state.auth.errorAuthUser);
  // console.log('hello', signInObj);
  const onSubmit = () => {
    if (
      (email || email !== undefined || email !== 'undefined' || email !== '') &&
      (password || password !== undefined || password !== 'undefined' || password !== '')
    ) {
      dispatch(AuhMethods[method].onLogin({ email, password }));
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box>
        {variant === 'default' ? (
          <Box className={classes.authThumb}>
            <CmtImage src={'http://sanchan-ott-resources.imgix.net/common/ottadmin/images/auth/login-img.png'} />
          </Box>
        ) : null}
        <Box className={classes.authContent}>
          <Box mb={7}>
            <CmtImage src={'http://sanchan-ott-resources.imgix.net/common/ottadmin/images/logo.png'} />
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Login
          </Typography>
          <form>
            <Box mb={2}>
              <TextField
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                onChange={event => setPassword(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <FormControlLabel
                className={classes.formcontrolLabelRoot}
                control={<Checkbox name="checkedA" />}
                label="Remember me"
              />
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink className={classes.loginLink} to="/forgot-password">
                  <IntlMessages id="appModule.forgotPassword" />
                </NavLink>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.signIn" />
              </Button>
              {signInObj && signInObj.status === 500 && (
                <p className={classes.loginErrorMgs}>{signInObj.data.errorMessage}</p>
              )}
            </Box>
          </form>

          {dispatch(AuhMethods[method].getSocialMediaIcons())}

          <ContentLoader />
        </Box>
      </Box>
      <Box className={classes.authBanner}>
        <CmtImage src={'http://sanchan-ott-resources.imgix.net/common/ottadmin/images/auth/login-img.png'} />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
