import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import Sidebar from './Sidebar';
import useStyles from './index.style';
import ChatContainer from './ChatContainer';
import ContentHeader from './ContentHeader';
import EditChatContainer from './EditChatContainer';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DestinationMethods } from '../../../services/auth';
import { CurrentDestinationMethod } from '../../../@jumbo/constants/AppConstants';

const TaskList = ({ method = CurrentDestinationMethod }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  console.log('id...111...type...', id + type);
  const DestinationViewData = useSelector(
    state =>
      state.auth &&
      state.auth.getdestinationViewData &&
      state.auth.getdestinationViewData.length > 0 &&
      state.auth.getdestinationViewData,
  );
  const DestinationData = useSelector(
    state =>
      state.auth &&
      state.auth.getdestinationList &&
      state.auth.getdestinationList.length > 0 &&
      state.auth.getdestinationList,
  );

  const Destination = useSelector(
    state =>
      state.auth &&
      state.auth.getdestinationList &&
      state.auth.getdestinationList.length > 0 &&
      state.auth.getdestinationList[0],
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(DestinationMethods[method].onDestinationList(token));
  }, []);
  if (type === 'isView') {
    let res =
      DestinationData &&
      Array.isArray(DestinationData) &&
      DestinationData.length > 0 &&
      DestinationData.find(elm => elm._id === id);
    let resview =
      DestinationViewData &&
      Array.isArray(DestinationViewData) &&
      DestinationViewData.length > 0 &&
      DestinationViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else if (type === 'isEdit') {
    let res =
      DestinationData &&
      Array.isArray(DestinationData) &&
      DestinationData.length > 0 &&
      DestinationData.find(elm => elm._id === id);
    let resview =
      DestinationViewData &&
      Array.isArray(DestinationViewData) &&
      DestinationViewData.length > 0 &&
      DestinationViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <EditChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else {
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={Destination} />
      </Box>
    );
  }
};

export default TaskList;
