import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  moduleImage:{
    maxWidth: 100,
    maxHeight: 100
  }
}));

export default function StorageGeneralTabContent(props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Name
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.name}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Image
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span"><img className={classes.moduleImage} src={props.DatafromCentered.image} /></Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Bucket
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.bucket}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Status
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.status}</Box>
              </Box>
            </Box>
          </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                AccessKey
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.accessKey}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                SecretKey
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.secretKey}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Region
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.region}</Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
}
