import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import Sidebar from './Sidebar';
import useStyles from './index.style';
import ChatContainer from './ChatContainer';
import ContentHeader from './ContentHeader';
import EditChatContainer from './EditChatContainer';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TransferServicesMethods } from '../../../services/auth';
import { CurrentTransferServicesMethod } from '../../../@jumbo/constants/AppConstants';

const TaskList = ({ method = CurrentTransferServicesMethod }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  console.log('id...111...type...', id + type);
  const TransferServicesViewData = useSelector(
    state =>
      state.auth &&
      state.auth.gettransferservicesViewData &&
      state.auth.gettransferservicesViewData.length > 0 &&
      state.auth.gettransferservicesViewData,
  );
  const TransferServicesData = useSelector(
    state =>
      state.auth &&
      state.auth.gettransferservicesList &&
      state.auth.gettransferservicesList.length > 0 &&
      state.auth.gettransferservicesList,
  );

  const TransferServices = useSelector(
    state =>
      state.auth &&
      state.auth.gettransferservicesList &&
      state.auth.gettransferservicesList.length > 0 &&
      state.auth.gettransferservicesList[0],
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(TransferServicesMethods[method].onTransferServicesList(token));
  }, []);

  if (type === 'isView') {
    let res =
      TransferServicesData &&
      Array.isArray(TransferServicesData) &&
      TransferServicesData.length > 0 &&
      TransferServicesData.find(elm => elm._id === id);
    let resview =
      TransferServicesViewData &&
      Array.isArray(TransferServicesViewData) &&
      TransferServicesViewData.length > 0 &&
      TransferServicesViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else if (type === 'isEdit') {
    let res =
      TransferServicesData &&
      Array.isArray(TransferServicesData) &&
      TransferServicesData.length > 0 &&
      TransferServicesData.find(elm => elm._id === id);
    let resview =
      TransferServicesViewData &&
      Array.isArray(TransferServicesViewData) &&
      TransferServicesViewData.length > 0 &&
      TransferServicesViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <EditChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else {
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={TransferServices} />
      </Box>
    );
  }
};

export default TaskList;
