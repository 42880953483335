import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Configs Page', isActive: true },
];

const Configs = () => {
  return (
    <PageContainer heading={<IntlMessages id="pages.Configs" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box>
            {/* <h1>Comming Soon</h1> */}
            <IntlMessages id="pages.Configs" />
            {/* <p>this is Configs page</p> */}
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Configs;
