import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditUserGeneralInfo from './EditUserGeneralInfo';
import EditSocialProfiles from './EditSocialProfiles';
import EditImages from './EditImages';
import EditUserPermission from './EditUserPermission';
import EditUserDevices from './EditUserDevices';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab label="User" {...a11yProps(0)} />
          <Tab label="Social Profiles" {...a11yProps(1)} />
          <Tab label="Images" {...a11yProps(2)} />
          <Tab label="Permission" {...a11yProps(3)} />
          <Tab label="Devices" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <EditUserGeneralInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditSocialProfiles />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EditImages />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EditUserPermission />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EditUserDevices />
      </TabPanel>
    </Box>
  );
}
