import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import Sidebar from './Sidebar';
import useStyles from './index.style';
import ChatContainer from './ChatContainer';
import EditChatContainer from './EditChatContainer';
import CreateTenantContainer from './CreateTenantContainer';
import CreateAwsContainer from './CreateAwsContainer';
import EditAwsContainer from './EditAwsContainer';
import CreateIconikContainer from './CreateIconikContainer';
import CreateAsperaContainer from './CreateAsperaContainer';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ClientMethods } from '../../../services/auth';
import { CurrentClientMethod } from '../../../@jumbo/constants/AppConstants';

const TaskList = ({ method = CurrentClientMethod }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type, tenantType } = useParams();
  console.log('id...12345...type...', id + '|' + type + '|' + tenantType);
  const ClientViewData = useSelector(
    state =>
      state.auth && state.auth.getclientViewData && state.auth.getclientViewData.length > 0 && state.auth.getclientViewData,
  );
  const ClientData = useSelector(
    state => state.auth && state.auth.getclientList && state.auth.getclientList.length > 0 && state.auth.getclientList,
  );

  const Client = useSelector(
    state => state.auth && state.auth.getclientList && state.auth.getclientList.length > 0 && state.auth.getclientList[0],
  );

  const ClientAwsData = useSelector(
    state =>
      state.auth && state.auth.getclientList && state.auth.getclientList.length > 0 && state.auth.getclientList[0].aws,
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(ClientMethods[method].onClientList(token));
  }, []);

  console.log('Client :Client', Client);

  if (type === 'isView') {
    let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    let resview =
      ClientViewData &&
      Array.isArray(ClientViewData) &&
      ClientViewData.length > 0 &&
      ClientViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else if (type === 'isEdit') {
    let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    let resview =
      ClientViewData &&
      Array.isArray(ClientViewData) &&
      ClientViewData.length > 0 &&
      ClientViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <EditChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else if (type === 'isTenant') {
    // let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    // let resview =
    //   ClientViewData &&
    //   Array.isArray(ClientViewData) &&
    //   ClientViewData.length > 0 &&
    //   ClientViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <CreateTenantContainer />
      </Box>
    );
  } else {
    //  else if (type === 'tenant') {
    //   let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    //   let resview =
    //     ClientViewData &&
    //     Array.isArray(ClientViewData) &&
    //     ClientViewData.length > 0 &&
    //     ClientViewData.find(elm => elm._id === id);
    //   return (
    //     <Box className={classes.inBuildAppContainer}>
    //       {/* <ContentHeader /> */}
    //       <Sidebar />
    //       <CreateAwsContainer FormData={resview ? resview : res} />
    //     </Box>
    //   );
    // }
    //else if (type === 'aws' && tenantType === 'update') {
    //   console.log('123');
    //   let Awsres =
    //     ClientAwsData && Array.isArray(ClientAwsData) && ClientAwsData.length > 0 && ClientAwsData.find(elm => elm.id === id);
    //   console.log('Awsres', Awsres);
    //   return (
    //     <Box className={classes.inBuildAppContainer}>
    //       <Sidebar />
    //       <EditAwsContainer FormData={Awsres} />
    //     </Box>
    //   );
    // } else if (type === 'iconik' && tenantType === 'create') {
    //   let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    //   let resview =
    //     ClientViewData &&
    //     Array.isArray(ClientViewData) &&
    //     ClientViewData.length > 0 &&
    //     ClientViewData.find(elm => elm._id === id);
    //   return (
    //     <Box className={classes.inBuildAppContainer}>
    //       {/* <ContentHeader /> */}
    //       <Sidebar />
    //       <CreateIconikContainer FormData={resview ? resview : res} />
    //     </Box>
    //   );
    // } else if (type === 'aspera' && tenantType === 'create') {
    //   let res = ClientData && Array.isArray(ClientData) && ClientData.length > 0 && ClientData.find(elm => elm._id === id);
    //   let resview =
    //     ClientViewData &&
    //     Array.isArray(ClientViewData) &&
    //     ClientViewData.length > 0 &&
    //     ClientViewData.find(elm => elm._id === id);
    //   return (
    //     <Box className={classes.inBuildAppContainer}>
    //       {/* <ContentHeader /> */}
    //       <Sidebar />
    //       <CreateAsperaContainer FormData={resview ? resview : res} />
    //     </Box>
    //   );
    // }
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={Client} />
      </Box>
    );
  }
};

export default TaskList;
