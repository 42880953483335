import React, { useState } from 'react';
import { Box, InputBase, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './SidebarHeader.style';
import Popover from '@material-ui/core/Popover';
import ProfileDetail from './ProfileDetail';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClientUpdateMethods } from '../../../../services/auth';
import { CurrentClientUpdateMethods } from '../../../../@jumbo/constants/AppConstants';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';

const SidebarHeader = ({ searchText, setSearchText, method = CurrentClientUpdateMethods }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userStatus, setUserStatus] = useState('Online');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatusColor = () => {
    switch (userStatus) {
      case 'Online':
        return '#8DCD03';
      case 'Busy':
        return '#FF8C00';
      case "Don't Disturb":
        return '#E00930';
      default:
        return '#C1C1C1';
    }
  };
  const open = Boolean(anchorEl);
  const classes = useStyles({ statusColor: getStatusColor() });
  const id = open ? 'user-popover' : undefined;
  const dispatch = useDispatch();
  const history = useHistory();
  const [addClient, setAddClient] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');

  const [nameError, setnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleClickOpen = () => {
    setAddClient(true);
  };
  const addClientClose = () => {
    setAddClient(false);
  };
  const token = localStorage.getItem('token');

  const createValidation = () => {
    if (!name) {
      setnameError(requiredMessage);
    } else if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    } else if (!address) {
      setAddressError(requiredMessage);
    } else if (!phone) {
      setPhoneError(requiredMessage);
    } else return true;
  };

  const submitAddClient = () => {
    if (createValidation()) {
      const data = { name: name, email: email, address: address, phone: phone };
      console.log('data', data);
      dispatch(ClientUpdateMethods[method].OnClientUpdate(token, data));
      setAddClient(false);
      history.push('/clients');
    }
  };

  return (
    <Box className={classes.sidebarHeaderRoot}>
      <Box className={classes.userRoot}>
        <Box className={clsx(classes.userInfo, 'Cmt-user-info')}>
          <Typography className={classes.userTitle} component="h3" variant="h6">
            Clients
          </Typography>
        </Box>
        <Box>
          <IconButton aria-label="Add" className={classes.margin} onClick={handleClickOpen}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.searchRoot}>
        <InputBase
          placeholder={'Search here...'}
          inputProps={{ 'aria-label': 'search' }}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <SearchIcon />
      </Box>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          onClose={addClientClose}
          aria-labelledby="customized-dialog-title"
          open={addClient}>
          <DialogTitle id="customized-dialog-title" onClose={addClientClose}>
            Add Client
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setnameError('');
                      }}
                      helperText={nameError}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="appModule.email"
                      label="Email"
                      onChange={event => {
                        setEmail(event.target.value);
                        setEmailError('');
                      }}
                      helperText={emailError}
                      defaultValue={email}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="appModule.address"
                      label="Address"
                      onChange={event => {
                        setAddress(event.target.value);
                        setAddressError('');
                      }}
                      helperText={addressError}
                      defaultValue={address}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="appModule.phone"
                      label="Phone Number"
                      onChange={event => setPhone(event.target.value)}
                      onChange={event => {
                        setPhone(event.target.value);
                        setPhoneError('');
                      }}
                      helperText={phoneError}
                      defaultValue={phone}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addClientClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddClient()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default SidebarHeader;
