import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DestinationMethods } from '../../../../services/auth';
import CmtCard from '../../../../@coremat/CmtCard';
import { CurrentDestinationMethod } from '../../../../@jumbo/constants/AppConstants';
import { Box } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
// import { modernWidget } from '../../../../@fake-db/widgets/modern';
import { NavLink } from 'react-router-dom';
import { DestinationViewMethods } from '../../../../services/auth';
import { CurrentDestinationViewMethods } from '../../../../@jumbo/constants/AppConstants';

const useStyles = makeStyles(() => ({
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  navLink: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    width: '100%',
    borderBottom: '1px solid rgb(46 46 46)',
  },
  userBoxRoot: {
    width: 50,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',

    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
}));

const UserSummery = ({ method = CurrentDestinationMethod, method1 = CurrentDestinationViewMethods }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const DestinationData = useSelector(state => state.auth.getdestinationList);

  console.log('DestinationData.....', DestinationData);

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(DestinationMethods[method].onDestinationList(token));
  }, []);

  const onSideNavInfoClick = item => {
    console.log('item....', item);
    dispatch(DestinationViewMethods[method1].onDestinationViewData(item));
  };

  return (
    <CmtCard className={classes.cardRoot}>
      {DestinationData &&
        DestinationData.length > 0 &&
        DestinationData.map(elm => (
          <Box display="flex" alignItems="center" height={1}>
            <NavLink className={classes.navLink} to={'/destinations/' + elm._id + '/isView'}>
              <Box className={classes.userBoxRoot}>
                {/* <CmtAvatar size={75} src={userSummary.profile_pic} /> */}
                <CmtAvatar size={75} src={'profile_pic'} />
              </Box>
              <Box ml={{ xs: 5, xl: 8 }} onClick={() => onSideNavInfoClick(elm._id)}>
                <Typography component="div" variant="h4">
                  {elm.name}
                </Typography>
              </Box>
            </NavLink>
          </Box>
        ))}
    </CmtCard>
  );
};

export default UserSummery;
