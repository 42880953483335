import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { DestinationUpdateMethods, StoragesMethods, TransferServicesMethods, DestinationLookupListMethod} from '../../../../services/auth';
import { CurrentDestinationUpdateMethods, CurrentStoragesMethod, CurrentTransferServicesMethod, CurrentDestinationLookupMethod } from '../../../../@jumbo/constants/AppConstants';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  moduleImage:{
    maxWidth: 100,
    maxHeight: 100
  }
}));

export default function BasicTextFields({FormData,method = CurrentDestinationUpdateMethods}) {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState(FormData.name);
  const [type, setType] = useState(FormData.type);
  const [image, setImage] = useState(FormData.image);
  const [transcodingTemplate, setTranscodingTemplate] = useState(FormData.transcodingTemplate);
  const [transcoding, setTranscoding] = useState(FormData.transcoding);
  const [transcription, setTranscription] = useState(FormData.transcription);
  const [recognization, setRecognization] = useState(FormData.recognization);
  const [storageId, setStorageId] = useState(FormData.storageId);
  const [transferMethodId, setTransferMethodId] = useState(FormData.transferMethodId);
  console.log("Form Data 2",FormData);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  
  const onSubmit = () => {
    const data = { _id: FormData._id, name: name, type: type, image: image, transcodingTemplate: transcodingTemplate,
      transcoding: transcoding, transcription: transcription, recognization: recognization, storageId: storageId, transferMethodId: transferMethodId };
    dispatch(DestinationUpdateMethods[method].OnDestinationUpdate(token, data));
    history.push('/destinations/' + FormData._id + '/isView');
  };

  const storagesData = useSelector(state => state.auth.getstoragesList);
  const TransferServicesData = useSelector(state => state.auth.gettransferservicesList);
  const destinationLookupData = useSelector(state => state.auth.getDestinationLookupList);

  useEffect(() => {
    dispatch(StoragesMethods[CurrentStoragesMethod].onStoragesList(token));
    dispatch(TransferServicesMethods[CurrentTransferServicesMethod].onTransferServicesList(token));
    dispatch(DestinationLookupListMethod[CurrentDestinationLookupMethod].onDestinationLookupList(token));
  }, []);

  const handleImageChange = event => {
    setType(event.target.value);
    destinationLookupData.forEach((item,index)=>{
      if(event.target.value==item.name){
        setImage(item.image);
      }
    })
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <GridContainer>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <TextField className="" id="appModule.name" label="Name" onChange={event => setName(event.target.value)} defaultValue={name} />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="type-native-simple">Type</InputLabel>
              <Select
                native
                value={type}
                onChange={event => handleImageChange(event)}
                inputProps={{
                  name: 'type',
                  id: 'type-native-simple',
                }}>
                <option aria-label="None" value="" />
                {destinationLookupData && destinationLookupData.length > 0 &&
                destinationLookupData.map(dld => (
                <option value={dld.name}>{dld.name}</option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
            <img className={classes.moduleImage} src={image} /> 
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="template-native-simple">TranscodingTemplate</InputLabel>
              <Select
                native
                value={transcodingTemplate}
                onChange={event => setTranscodingTemplate(event.target.value)}
                inputProps={{
                  name: 'TranscodingTemplate',
                  id: 'template-native-simple',
                }}>
                <option aria-label="None" value="" />
                <option value="default">Default</option>
                {/* <option value={20}>False</option> */}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <FormControl className={classes.formControl}>
              <InputLabel htmlFor="transcoding-native-simple">Transcoding</InputLabel>
              <Select
                native
                value={transcoding}
                onChange={event => setTranscoding(event.target.value)}
                inputProps={{
                  name: 'transcoding',
                  id: 'transcoding-native-simple',
                }}>
                <option value="true">True</option>
                <option value="false">False</option>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Button onClick={onSubmit} variant="contained" className={classes.button} color="primary">
                {' '}Submit{' '}
              </Button>
              <NavLink className={classes.navLink} to={'/destinations/' + FormData._id + '/isView'}>
              <Button variant="contained" className={classes.button} color="secondary">
              {' '}Cancel{' '}
              </Button>
              </NavLink>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="transcription-native-simple">Transcription</InputLabel>
              <Select
                native
                value={transcription}
                onChange={event => setTranscription(event.target.value)}
                inputProps={{
                  name: 'transcription',
                  id: 'transcription-native-simple',
                }}>
                <option value="true">True</option>
                <option value="false">False</option>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="recognization-native-simple">Recognization</InputLabel>
              <Select
                native
                value={recognization}
                onChange={event => setRecognization(event.target.value)}
                inputProps={{
                  name: 'recognization',
                  id: 'recognization-native-simple',
                }}>
                <option value="true">True</option>
                <option value="false">False</option>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="storage-native-simple">Storage Id</InputLabel>
              <Select
                native
                value={storageId}
                onChange={event => setStorageId(event.target.value)}
                inputProps={{
                  name: 'storage',
                  id: 'storage-native-simple',
                }}>
                <option value=""></option>
                {storagesData && storagesData.length > 0 &&
                storagesData.map(sts => (
                <option value={sts._id}>{sts.name}</option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="transferMethod-native-simple">Transfer Method Id</InputLabel>
              <Select
                native
                value={transferMethodId}
                onChange={event => setTransferMethodId(event.target.value)}
                inputProps={{
                  name: 'transferMethod',
                  id: 'transferMethod-native-simple',
                }}>
                <option value=""></option>
                {TransferServicesData && TransferServicesData.length > 0 &&
                TransferServicesData.map(tsd => (
                <option value={tsd._id}>{tsd.name}</option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </GridContainer>
    </form>
  );
}
