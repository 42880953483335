import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCard from '../../../../@coremat/CmtCard';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  DestinationMethods,
  DestinationUpdateMethods,
  StoragesMethods,
  TransferServicesMethods,
  DestinationLookupListMethod,
} from '../../../../services/auth';
import {
  CurrentDestinationMethod,
  CurrentDestinationUpdateMethods,
  CurrentStoragesMethod,
  CurrentTransferServicesMethod,
  CurrentDestinationLookupMethod,
} from '../../../../@jumbo/constants/AppConstants';
const columns = [
  // { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
  },
];
function createData(name, type) {
  return { name, type };
}
const rows = [createData('Sanchan Inc', 'Peafowl'), createData('Test Inc', 'Peafowl'), createData('Sample Inc', 'Peafowl')];
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Destinations(props, { method = CurrentDestinationMethod }) {
  const classes = useStyles();
  const { id, pageType } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const [addDestination, setAddDestination] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState('');
  const [transcodingTemplate, setTranscodingTemplate] = useState('default');
  const [transcoding, setTranscoding] = useState(false);
  const [transcription, setTranscription] = useState(false);
  const [recognization, setRecognization] = useState(false);
  const [storageId, setStorageId] = useState('');
  const [transferMethodId, setTransferMethodId] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleAddClickOpen = () => {
    setAddDestination(true);
  };
  const addDestinationClose = () => {
    setAddDestination(false);
  };
  const token = localStorage.getItem('token');
  const submitAddDestination = () => {
    const data = {
      name: name,
      type: type,
      image: image,
      transcodingTemplate: transcodingTemplate,
      transcoding: transcoding,
      transcription: transcription,
      recognization: recognization,
      storageId: storageId,
      transferMethodId: transferMethodId,
    };
    dispatch(DestinationUpdateMethods[CurrentDestinationUpdateMethods].OnDestinationUpdate(token, data, id));
    setAddDestination(false);
    //history.push('/destinations');
  };
  const DestinationData = useSelector(
    state =>
      state.auth &&
      state.auth.getdestinationList &&
      state.auth.getdestinationList.length > 0 &&
      state.auth.getdestinationList,
  );
  const storagesData = useSelector(state => state.auth.getstoragesList);
  const TransferServicesData = useSelector(state => state.auth.gettransferservicesList);
  const destinationLookupData = useSelector(state => state.auth.getDestinationLookupList);

  useEffect(() => {
    dispatch(DestinationMethods[method].onDestinationList(token, id));
    dispatch(StoragesMethods[CurrentStoragesMethod].onStoragesList(token, id));
    dispatch(TransferServicesMethods[CurrentTransferServicesMethod].onTransferServicesList(token, id));
    dispatch(DestinationLookupListMethod[CurrentDestinationLookupMethod].onDestinationLookupList(token));
  }, []);

  console.log(' My Storages', storagesData);
  console.log(' My transfer', TransferServicesData);
  console.log(' My dest lookup', destinationLookupData);
  let DestinationDataList = DestinationData && DestinationData.length > 0 ? DestinationData : [];

  const handleImageChange = event => {
    setType(event.target.value);
    destinationLookupData.forEach((item, index) => {
      if (event.target.value == item.name) {
        setImage(item.image);
      }
    });
  };
  return (
    <Box>
      <Grid>
        <CmtCard className={useStyles.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box ml={{ xs: 5, xl: 8 }}>
              <Typography component="div" variant="h4">
                Destinations
              </Typography>
            </Box>
            <Box className={classes.clientActions}>
              <IconButton aria-label="Add" className={classes.margin} onClick={handleAddClickOpen}>
                <AddBox />
              </IconButton>
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {DestinationDataList && DestinationDataList.length > 0 ? (
              <TableBody>
                {DestinationDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IconButton aria-label="edit" className={classes.margin}>
                          <Edit />
                        </IconButton>
                        <IconButton>
                          <Delete aria-label="delete" className={classes.margin} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              'No Data Found'
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addDestinationClose}
          aria-labelledby="customized-dialog-title"
          open={addDestination}>
          <DialogTitle id="customized-dialog-title" onClose={addDestinationClose}>
            Add Destination
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => setName(event.target.value)}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                    <Select
                      native
                      value={type}
                      onChange={event => handleImageChange(event)}
                      inputProps={{
                        name: 'type',
                        id: 'type-native-simple',
                      }}>
                      <option aria-label="None" value="" />
                      {destinationLookupData &&
                        destinationLookupData.length > 0 &&
                        destinationLookupData.map(dld => <option value={dld.name}>{dld.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <img className={classes.moduleImage} src={image} />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="template-native-simple">TranscodingTemplate</InputLabel>
                    <Select
                      native
                      value={transcodingTemplate}
                      onChange={event => setTranscodingTemplate(event.target.value)}
                      inputProps={{
                        name: 'TranscodingTemplate',
                        id: 'template-native-simple',
                      }}>
                      <option aria-label="None" value="" />
                      <option value="default">Default</option>
                      {/* <option value={20}>False</option> */}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transcoding-native-simple">Transcoding</InputLabel>
                    <Select
                      native
                      value={transcoding}
                      onChange={event => setTranscoding(event.target.value)}
                      inputProps={{
                        name: 'transcoding',
                        id: 'transcoding-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transcription-native-simple">Transcription</InputLabel>
                    <Select
                      native
                      value={transcription}
                      onChange={event => setTranscription(event.target.value)}
                      inputProps={{
                        name: 'transcription',
                        id: 'transcription-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="recognization-native-simple">Recognization</InputLabel>
                    <Select
                      native
                      value={recognization}
                      onChange={event => setRecognization(event.target.value)}
                      inputProps={{
                        name: 'recognization',
                        id: 'recognization-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="storage-native-simple">Storage Id</InputLabel>
                    <Select
                      native
                      value={storageId}
                      onChange={event => setStorageId(event.target.value)}
                      inputProps={{
                        name: 'storage',
                        id: 'storage-native-simple',
                      }}>
                      <option value="" />
                      {storagesData &&
                        storagesData.length > 0 &&
                        storagesData.map(sts => <option value={sts._id}>{sts.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transferMethod-native-simple">Transfer Method Id</InputLabel>
                    <Select
                      native
                      value={transferMethodId}
                      onChange={event => setTransferMethodId(event.target.value)}
                      inputProps={{
                        name: 'transferMethod',
                        id: 'transferMethod-native-simple',
                      }}>
                      <option value="" />
                      {TransferServicesData &&
                        TransferServicesData.length > 0 &&
                        TransferServicesData.map(tsd => <option value={tsd._id}>{tsd.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addDestinationClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddDestination()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
