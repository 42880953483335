import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import useStyles from './index.style';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import { onUserSelect } from '../../../redux/actions/Chat';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import ClientHeader from './ClientHeader';
import { Grid } from '@material-ui/core';
import { useLocation, useParams, NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '../../../@coremat/CmtCard';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import { ClientDeleteMethod } from '../../../services/auth';
import { CurrentClientDeleteMethod } from '../../../@jumbo/constants/AppConstants';
import GridContainer from '@jumbo/components/GridContainer';

const useStyle = makeStyles(() => ({
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  userBoxRoot: {
    width: 85,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '20px',
    overflow: 'hidden',

    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
}));

const ContentHeader = (props, { method = CurrentClientDeleteMethod }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id, type, tenantType } = useParams();
  const [open, setOpen] = React.useState(false);

  console.log('id......type...', id + type);
  const ClientViewData = useSelector(
    state =>
      state.auth &&
      state.auth.getclientViewData &&
      state.auth.getclientViewData.length > 0 &&
      state.auth.getclientViewData[0],
  );
  const ClientData = useSelector(
    state => state.auth && state.auth.getclientList && state.auth.getclientList.length > 0 && state.auth.getclientList[0],
  );
  // console.log('props......', props);
  // console.log("ClientData.........",ClientData)

  const handleClickOpen = item => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = clientId => {
    setOpen(false);
    //alert(clientId);
    dispatch(ClientDeleteMethod[method].onClientDelete(clientId));
    history.push('/clients');
  };

  console.log('FormDataToHeader', props);
  console.log('props.FormDataToHeader.name', props.FormDataToHeader.name);
  let passId = props && props.FormDataToHeader && props.FormDataToHeader._id ? props.FormDataToHeader._id : id;
  // console.log("passId................",passId)
  return (
    <GridContainer alignItems="center">
      <Grid item xs={12} sm={6} md={6}>
        {/* <ClientHeader /> */}
        <CmtCard className={useStyle.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box>
              <Typography component="div" variant="h4">
                {type !== 'tenant' && tenantType ===undefined ? (props && props.FormDataToHeader && props.FormDataToHeader.name):'Create Tenant'}
              </Typography>
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      {type !== 'isEdit' && type !== 'tenant' &&
     <Grid item xs={12} sm={6} md={6} className="btn_block">
        <IconButton aria-label="delete" className={classes.margin} onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
        <NavLink className={classes.navLink} to={'/clients/' + passId + '/isEdit'}>
          <IconButton aria-label="edit" className={classes.margin}>
            <EditIcon />
          </IconButton>
        </NavLink>
      </Grid>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleOk(passId)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
};
export default ContentHeader;
