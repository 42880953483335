import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenusMethods } from '../../../../../services/auth';
import { CurrentMenusMethod } from '../../../../constants/AppConstants';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { Storage } from '@material-ui/icons';
import { Dashboard } from '@material-ui/icons';
import { Sports } from '@material-ui/icons';
import { DevicesOther } from '@material-ui/icons';
import { SwapHoriz } from '@material-ui/icons';
import { DesktopMac } from '@material-ui/icons';
import { SignalWifi0Bar } from '@material-ui/icons';
import { FastRewind } from '@material-ui/icons';
import { FiberDvr } from '@material-ui/icons';
import { Flag } from '@material-ui/icons';
import { Wifi } from '@material-ui/icons';
import { PermContactCalendar } from '@material-ui/icons';
import { SupervisorAccount } from '@material-ui/icons';
import { Settings } from '@material-ui/icons';
import { IdentityProviders } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = ({ method = CurrentMenusMethod }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AppMenus = useSelector(state => state.auth.getmenus);
  console.log('AppMenus', AppMenus);

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(MenusMethods[method].onMainMenus(token));
  }, []);

  let childrenVal = [];
  AppMenus &&
    AppMenus.result &&
    AppMenus.result.length > 0 &&
    AppMenus.result.map(function(elm, i) {
      let subChildren = [];
      let type = 'item';
      elm &&
        elm.submenus &&
        elm.submenus.menus &&
        elm.submenus.menus.length > 0 &&
        elm.submenus.menus.map(function(sub, i) {
          type = 'collapse';
          let subObj = {
            name: <IntlMessages id={sub.menu} />,
            icon: <ArrowForward />,
            type: type,
            link: sub.route,
            children: subObj,
          };
          subChildren.push(subObj);
        });

      let obj = {
        name: <IntlMessages id={elm.menu} />,
        icon: <Dashboard />,
        type: type,
        link: '/' + elm.route,
        children: subChildren,
      };
      childrenVal.push(obj);
    });

  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: childrenVal,
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
