import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import useStyles from './index.style';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import { onUserSelect } from '../../../redux/actions/Chat';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import StorageHeader from './StorageHeader';
import { Grid } from '@material-ui/core';
import { useLocation, useParams, NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '../../../@coremat/CmtCard';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import { StoragesDeleteMethod } from '../../../services/auth';
import { CurrentStoragesDeleteMethod } from '../../../@jumbo/constants/AppConstants';

const useStyle = makeStyles(() => ({
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  userBoxRoot: {
    width: 85,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '20px',
    overflow: 'hidden',

    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
}));

export default function ContentHeader(props, { method = CurrentStoragesDeleteMethod }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id, type } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = item => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = storageId => {
    setOpen(false);
    //alert(storageId);
    dispatch(StoragesDeleteMethod[method].onStoragesDelete(storageId));
    history.push('/storages');
  };

  let passId = props && props.FormDataToHeader && props.FormDataToHeader._id ? props.FormDataToHeader._id : id;

  return (
    <Box className={useStyle.appContentHeader}>
      <Grid>
        {/* <StorageHeader /> */}
        <CmtCard className={useStyle.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box className={useStyle.userBoxRoot}>
              {/* <CmtAvatar size={75} src={userSummary.profile_pic} /> */}
              <CmtAvatar size={75} src={'profile_pic'} />
            </Box>
            <Box ml={{ xs: 5, xl: 8 }}>
              <Typography component="div" variant="h4">
                {props && props.FormDataToHeader && props.FormDataToHeader.name}
              </Typography>
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Box className={classes.clientActions}>
        <IconButton aria-label="delete" className={classes.margin} onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
        <NavLink className={classes.navLink} to={'/storages/' + passId + '/isEdit'}>
          <IconButton aria-label="edit" className={classes.margin}>
            <EditIcon />
          </IconButton>
        </NavLink>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleOk(passId)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
