import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function GeneralInfoTabContent(props) {
  const classes = useStyles();
  console.log('final.......', props);
  return (
    <Box className={classes.root}>
      <GridContainer className="tabSub">
        <Grid item xs={12} sm={6} md={6}>
          <Box className="mb0" display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Name
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.name}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="mb0" display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Email
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.email}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="mb0" display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Status
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.status}</Box>
              </Box>
            </Box>
          </Box>
          {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Updated
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">time</Box>
              </Box>
            </Box>
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="mb0" display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Address
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.address}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="mb0" display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Phone No
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">{props && props.DatafromCentered && props.DatafromCentered.phone}</Box>
              </Box>
            </Box>
          </Box>
          {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box>
              <Box component="span" fontSize={12} color="text.secondary">
                Created
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="span">time</Box>
              </Box>
            </Box>
          </Box> */}
        </Grid>
      </GridContainer>
    </Box>
  );
}
