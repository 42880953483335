import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  GET_MENUS_LIST,
  GET_DESTINATION_LOOKUP,
  GET_TRANSFER_LOOKUP,
  GET_CLIENT_LIST,
  SET_CLIENT_VIEW_DATA,
  SET_CLIENT_CREATE_DATA,
  SET_CLIENT_UPDATE_DATA,
  SET_CLIENT_DELETE_DATA,
  GET_TENANT_LIST,
  GET_TENANT_CONFIG,
  GET_DESTINATION_LIST,
  SET_DESTINATION_VIEW_DATA,
  SET_DESTINATION_CREATE_DATA,
  SET_DESTINATION_UPDATE_DATA,
  SET_DESTINATION_DELETE_DATA,
  GET_STORAGES_LIST,
  SET_STORAGES_VIEW_DATA,
  SET_STORAGES_CREATE_DATA,
  SET_STORAGES_UPDATE_DATA,
  SET_STORAGES_DELETE_DATA,
  GET_TRANSFER_SERVICES_LIST,
  SET_TRANSFER_SERVICES_VIEW_DATA,
  SET_TRANSFER_SERVICES_CREATE_DATA,
  SET_TRANSFER_SERVICES_UPDATE_DATA,
  SET_TRANSFER_SERVICES_DELETE_DATA,
  ERROR_AUTH_USER,
  SET_CLIENT_AWS_CREATE_DATA,
  SET_CLIENT_AWS_UPDATE_DATA,
  SET_AWS_DELETE_DATA,
  SET_ICONIK_CREATE_DATA,
  SET_ASPERA_CREATE_DATA,
  SET_ICONIK_UPDATE_DATA,
  SET_ICONIK_DELETE_DATA,
  SET_ASPERA_UPDATE_DATA,
  SET_ASPERA_DELETE_DATA,
} from '../../@jumbo/constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const setErrorAuthUser = error => {
  return dispatch => {
    dispatch({
      type: ERROR_AUTH_USER,
      payload: error,
    });
  };
};

export const setMainMenusList = menu => {
  console.log('menu', menu);
  return dispatch => {
    dispatch({
      type: GET_MENUS_LIST,
      payload: menu,
    });
  };
};

export const setDestinationLookupList = DestinationLookup => {
  console.log('Destination lookup', DestinationLookup);
  return dispatch => {
    dispatch({
      type: GET_DESTINATION_LOOKUP,
      payload: DestinationLookup,
    });
  };
};

export const setTransferLookupList = TransferLookup => {
  console.log('Transfer lookup', TransferLookup);
  return dispatch => {
    dispatch({
      type: GET_TRANSFER_LOOKUP,
      payload: TransferLookup,
    });
  };
};

export const setClientList = client => {
  console.log('client', client);
  return dispatch => {
    dispatch({
      type: GET_CLIENT_LIST,
      payload: client,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const setClientViewData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_VIEW_DATA,
      payload: data,
    });
  };
};

export const setClientCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_CREATE_DATA,
      payload: data,
    });
  };
};

export const setClientUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setClientDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_DELETE_DATA,
      payload: data,
    });
  };
};

export const setIconikDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_ICONIK_DELETE_DATA,
      payload: data,
    });
  };
};

export const setAsperaDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_ASPERA_DELETE_DATA,
      payload: data,
    });
  };
};

export const setAwsDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_AWS_DELETE_DATA,
      payload: data,
    });
  };
};

export const setClientCreateAwsData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_AWS_CREATE_DATA,
      payload: data,
    });
  };
};

export const setIconikCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_ICONIK_CREATE_DATA,
      payload: data,
    });
  };
};

export const setIconikUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_ICONIK_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setAsperaUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_ASPERA_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setAsperaCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_ASPERA_CREATE_DATA,
      payload: data,
    });
  };
};

export const setClientUpdateAwsData = data => {
  return dispatch => {
    dispatch({
      type: SET_CLIENT_AWS_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setTenantList = tenant => {
  return dispatch => {
    dispatch({
      type: GET_TENANT_LIST,
      payload: tenant,
    });
  };
};

export const setTenantConfig = data => {
  return dispatch => {
    dispatch({
      type: GET_TENANT_CONFIG,
      payload: data,
    });
  };
};

export const setDestinationList = data => {
  return dispatch => {
    dispatch({
      type: GET_DESTINATION_LIST,
      payload: data,
    });
  };
};

export const setDestinationViewData = data => {
  return dispatch => {
    dispatch({
      type: SET_DESTINATION_VIEW_DATA,
      payload: data,
    });
  };
};

export const setDestinationCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_DESTINATION_CREATE_DATA,
      payload: data,
    });
  };
};

export const setDestinationUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_DESTINATION_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setDestinationDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_DESTINATION_DELETE_DATA,
      payload: data,
    });
  };
};

export const setStoragesList = data => {
  return dispatch => {
    dispatch({
      type: GET_STORAGES_LIST,
      payload: data,
    });
  };
};

export const setStoragesViewData = data => {
  return dispatch => {
    dispatch({
      type: SET_STORAGES_VIEW_DATA,
      payload: data,
    });
  };
};

export const setStoragesCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_STORAGES_CREATE_DATA,
      payload: data,
    });
  };
};

export const setStoragesUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_STORAGES_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setStoragesDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_STORAGES_DELETE_DATA,
      payload: data,
    });
  };
};

export const setTransferServicesList = data => {
  return dispatch => {
    dispatch({
      type: GET_TRANSFER_SERVICES_LIST,
      payload: data,
    });
  };
};

export const setTransferServicesViewData = data => {
  return dispatch => {
    dispatch({
      type: SET_TRANSFER_SERVICES_VIEW_DATA,
      payload: data,
    });
  };
};

export const setTransferservicesCreateData = data => {
  return dispatch => {
    dispatch({
      type: SET_TRANSFER_SERVICES_CREATE_DATA,
      payload: data,
    });
  };
};

export const setTransferservicesUpdateData = data => {
  return dispatch => {
    dispatch({
      type: SET_TRANSFER_SERVICES_UPDATE_DATA,
      payload: data,
    });
  };
};

export const setTransferservicesDeleteData = data => {
  return dispatch => {
    dispatch({
      type: SET_TRANSFER_SERVICES_DELETE_DATA,
      payload: data,
    });
  };
};
