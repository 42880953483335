import React, { useContext, useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import { useParams, NavLink } from 'react-router-dom';
import CmtCard from '../../../../@coremat/CmtCard';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { IconikCreateMethods, IconikUpdateMethods, IconikDeleteMethod } from '../../../../services/auth';
import {
  CurrentIconikCreateMethods,
  CurrentIconikUpdateMethods,
  CurrentIconikDeleteMethods,
} from '../../../../@jumbo/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const columns = [
  // { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'name', label: 'Name' },
  /*{
    id: 'host',
    label: 'Host',
    minWidth: 170,
  },
  {
    id: 'appID',
    label: 'App ID',
    minWidth: 170,
  },
  {
    id: 'token',
    label: 'Token',
    minWidth: 170,
  },*/
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));*/

export default function IconikContent(
  props,
  { method = CurrentIconikCreateMethods, method1 = CurrentIconikUpdateMethods, method2 = CurrentIconikDeleteMethods },
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log('final.......123', props);

  const { id, type, tenantType } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addClient, setAddClient] = useState(false);
  const [viewClient, setViewClient] = useState(false);
  const [data, setData] = useState({});
  //  let Editname = data ? data.name
  const [name, setName] = useState('');
  const [host, setHost] = useState('');
  const [appID, setAppID] = useState('');
  const [storageID, setStorageID] = useState('');
  const [token, setToken] = useState('');

  const [item, setItem] = useState('');
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');

  const [nameError, setNameError] = useState('');
  const [hostError, setHostError] = useState('');
  const [appIDError, setAppIDError] = useState('');
  const [storageIDError, setStorageIDError] = useState('');
  const [TokenError, setTokenError] = useState('');

  // console.log('data.......123', data);
  const createValidation = () => {
    let name = data ? data.name : name;
    let host = data ? data.host : host;
    let appID = data ? data.appID : appID;
    let storageID = data ? data.storageID : storageID;
    let token = data ? data.token : token;
    if (!name) {
      setNameError(requiredMessage);
    } else if (!host) {
      setHostError(requiredMessage);
    } else if (!appID) {
      setAppIDError(requiredMessage);
    } else if (!storageID) {
      setStorageIDError(requiredMessage);
    } else if (!token) {
      setTokenError(requiredMessage);
    } else return true;
  };

  const setDataUpdate = (key, dataValue, data) => {
    data[key] = dataValue;
    setData(data);
  };

  const handleDeleteClick = (item, itemId) => {
    setItem(item);
    setItemId(itemId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = (id, _id) => {
    setOpen(false);
    const data = {
      _id: _id,
      iconik: {
        id: id,
      },
    };
    dispatch(IconikDeleteMethod[method2].onIconikDelete(data));
    // history.push('/clients');
  };

  const handleAddClickOpen = () => {
    setData({});
    setAddClient(true);
  };

  const handleEditClickOpen = data => {
    if (data && data.id) {
      setData(data);
    }
    setAddClient(true);
  };
  const addClientClose = () => {
    setAddClient(false);
  };
  const handleViewClickOpen = data => {
    if (data && data.id) {
      setData(data);
    }
    setViewClient(true);
  };
  const viewClientClose = () => {
    setViewClient(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitAddClient = (_id, updateData) => {
    if (createValidation()) {
      if (updateData && updateData.id) {
        let data = {
          _id: _id,
          iconik: {
            id: updateData.id,
            name: updateData.name,
            host: updateData.host,
            appID: updateData.appID,
            storageID: updateData.storageID,
            token: updateData.token,
          },
        };
        dispatch(IconikUpdateMethods[method1].OnIconikUpdate(data));
        setAddClient(false);
      } else {
        let data = {
          _id: _id,
          iconik: {
            name: name,
            host: host,
            appID: appID,
            storageID: storageID,
            token: token,
          },
        };
        dispatch(IconikCreateMethods[method].OnIconikCreate(data));
        setAddClient(false);
        //   history.push('/clients');
      }
    }
  };

  let passId = props && props.DatafromCentered && props.DatafromCentered._id ? props.DatafromCentered._id : id;
  let iconikArray = props && props.DatafromCentered && props.DatafromCentered.iconik ? props.DatafromCentered.iconik : [];

  // console.log("nameeeeee", name)
  return (
    <Box>
      <Grid>
        <CmtCard className={useStyles.cardRoot}>
          <Box display="flex" alignItems="center" height={1}>
            <Box>
              <Typography component="div" variant="h4">
                Iconik List
              </Typography>
            </Box>
            <Box className={classes.clientActions}>
              <IconButton color="primary" aria-label="Add" className={classes.margin} onClick={handleAddClickOpen}>
                <AddBox />
              </IconButton>
            </Box>
          </Box>
        </CmtCard>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell width="20%"  align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            {iconikArray && iconikArray.length > 0 ? (
              <TableBody>
                {iconikArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(iconik => {
                  // console.log("iconik", iconik)
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={iconik.code}>
                      {columns.map(column => {
                        // console.log("columns....", columns)
                        const value = iconik[column.id];
                        return (
                          <TableCell align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <IconButton
                          aria-label="edit"
                          className={classes.margin}
                          onClick={e => {
                            handleEditClickOpen(iconik);
                          }}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          className={classes.margin}
                          onClick={e => {
                            handleViewClickOpen(iconik);
                          }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(iconik.id, passId)}>
                          <Delete aria-label="delete" className={classes.margin} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              'No Data Found'
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={iconikArray.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addClientClose}
          aria-labelledby="customized-dialog-title"
          open={addClient}>
          <DialogTitle id="customized-dialog-title" onClose={addClientClose}>
            {data && data.id ? 'Edit Iconik' : 'Add Iconik'}
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.appid"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setNameError('');
                        setDataUpdate('name', event.target.value, data);
                      }}
                      helperText={nameError}
                      defaultValue={data ? data.name : name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.AppID"
                      label="App ID"
                      onChange={event => {
                        setAppID(event.target.value);
                        setAppIDError('');
                        setDataUpdate('appID', event.target.value, data);
                      }}
                      helperText={appIDError}
                      defaultValue={data ? data.appID : appID}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.host"
                      label="Host"
                      onChange={event => {
                        setHost(event.target.value);
                        setHostError('');
                        setDataUpdate('host', event.target.value, data);
                      }}
                      helperText={hostError}
                      defaultValue={data ? data.host : host}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.storageID"
                      label="Storage ID"
                      onChange={event => {
                        setStorageID(event.target.value);
                        setStorageIDError('');
                        setDataUpdate('storageID', event.target.value, data);
                      }}
                      helperText={hostError}
                      defaultValue={data ? data.storageID : storageID}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.Token"
                      label="Token"
                      onChange={event => {
                        setToken(event.target.value);
                        setTokenError('');
                        setDataUpdate('token', event.target.value, data);
                      }}
                      helperText={TokenError}
                      defaultValue={data ? data.token : token}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addClientClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddClient(passId, data)} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleOk(item, itemId)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={viewClientClose}
          aria-labelledby="customized-dialog-title"
          open={viewClient}>
          <DialogTitle id="customized-dialog-title" onClose={viewClientClose}>
            View Iconik
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Name
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.name : name}</Box>
                </Box>
              </Grid>
			  <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Host
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.host : host}</Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  App ID
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.appID : appID}</Box>
                </Box>
              </Grid>
			  <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Storage ID
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.storageID : storageID}</Box>
                </Box>
              </Grid>
			  <Grid item xs={12} sm={6} md={6}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Token
				  </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16}>
                  <Box component="span">{data ? data.token : token}</Box>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={viewClientClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
