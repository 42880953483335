import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import Sidebar from './Sidebar';
import useStyles from './index.style';
import ChatContainer from './ChatContainer';
import ContentHeader from './ContentHeader';
import EditChatContainer from './EditChatContainer';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StoragesMethods } from '../../../services/auth';
import { CurrentStoragesMethod } from '../../../@jumbo/constants/AppConstants';

const TaskList = ({ method = CurrentStoragesMethod }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  console.log('id...111...type...', id + type);
  const StoragesViewData = useSelector(
    state =>
      state.auth &&
      state.auth.getstoragesViewData &&
      state.auth.getstoragesViewData.length > 0 &&
      state.auth.getstoragesViewData,
  );
  const StoragesData = useSelector(
    state => state.auth && state.auth.getstoragesList && state.auth.getstoragesList.length > 0 && state.auth.getstoragesList,
  );

  const Storages = useSelector(
    state =>
      state.auth && state.auth.getstoragesList && state.auth.getstoragesList.length > 0 && state.auth.getstoragesList[0],
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(StoragesMethods[method].onStoragesList(token));
  }, []);

  if (type === 'isView') {
    let res =
      StoragesData && Array.isArray(StoragesData) && StoragesData.length > 0 && StoragesData.find(elm => elm._id === id);
    let resview =
      StoragesViewData &&
      Array.isArray(StoragesViewData) &&
      StoragesViewData.length > 0 &&
      StoragesViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else if (type === 'isEdit') {
    let res =
      StoragesData && Array.isArray(StoragesData) && StoragesData.length > 0 && StoragesData.find(elm => elm._id === id);
    let resview =
      StoragesViewData &&
      Array.isArray(StoragesViewData) &&
      StoragesViewData.length > 0 &&
      StoragesViewData.find(elm => elm._id === id);
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <EditChatContainer FormData={resview ? resview : res} />
      </Box>
    );
  } else {
    return (
      <Box className={classes.inBuildAppContainer}>
        {/* <ContentHeader /> */}
        <Sidebar />
        <ChatContainer FormData={Storages} />
      </Box>
    );
  }
};

export default TaskList;
