import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import SamplePage from './Pages/SamplePage';
import Client from './Pages/Client';
import Dashboard from './Pages/Dashboard';
import Activities from './Pages/Activities';
// import ChannelProviders from './Pages/ChannelProviders';
import Configs from './Pages/Configs';
import UserDevices from './Pages/UserDevices';
import Directories from './Pages/Directories';
import Menus from './Pages/Menus';
// import Subnets from './Pages/Subnets';
import TransferServices from './Pages/TransferServices';
import ImportUsers from './Pages/ImportUsers';
import Groups from './Pages/Groups';
import UsersList from './Pages/UsersList';
// import VideoEncoders from './Pages/VideoEncoders';
// import VideoPlayers from './Pages/VideoPlayers';
// import Vpc from './Pages/Vpc';
import Storages from './Pages/Storages';
// import WorkStations from './Pages/WorkStations';
import Destinations from './Pages/Destinations';
// import IdentityProviders from './Pages/IdentityProviders';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const token = localStorage.getItem('token');
  console.log('authUser', authUser);
  if (location.pathname === '' || location.pathname === '/') {
    if (token) {
      return <Redirect to={'/dashboard'} />;
    } else {
      return <Redirect to={'/signin'} />;
    }
  } else if (authUser && location.pathname === '/signin' && token) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/sample-page" component={SamplePage} />
        <Route exact path="/clients" component={Client} />
        <Route exact path="/clients/:id/:type" component={Client} />
        <Route exact path="/client/:id/:type" component={Client} />
        {/* <Route exact path="/clients/:id/:type/:tenantType" component={Client} />
        <Route exact path="/clients/:id/:_id/:type/:tenantType" component={Client} />
        <Route exact path="/clients/:id/:type/:tenantType" component={Client} />
        <Route exact path="/clients/:id/:type/:awsType" component={Client} />
        <Route exact path="/clients/:id/:type/:iconikType" component={Client} />
        <Route exact path="/clients/:id/:type/:asperaType" component={Client} /> */}
        <Route exact path="/destinations" component={Destinations} />
        <Route path="/destinations/:id/:type" component={Destinations} />
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/storages" component={Storages} />
        <Route path="/storages/:id/:type" component={Storages} />
        <Route exact path="/transferservices" component={TransferServices} />
        <Route path="/transferservices/:id/:type" component={TransferServices} />
        <Route exact path="/users" component={UsersList} />
        <Route path="/users/:id/:type" component={UsersList} />

        {/* <Route path="/users/import" component={ImportUsers} />
        <Route path="/users/groups" component={Groups} /> */}

        {/* <Route path="/identityproviders" component={IdentityProviders} /> */}
        {/* <Route path="/users/activities" component={Activities} /> */}
        {/* <Route path="/channelProviders" component={ChannelProviders} /> */}
        <Route path="/settings/configs" component={Configs} />
        {/* <Route path="/users/devices" component={UserDevices} /> */}
        <Route path="/directories" component={Directories} />
        <Route path="/settings/menus" component={Menus} />
        {/* <Route path="/network/subnets" component={Subnets} /> */}
        {/* <Route path="/devices/videoEncoders" component={VideoEncoders} /> */}
        {/* <Route path="/network/vpcs" component={Vpc} /> */}
        {/* <Route path="/workStations" component={WorkStations} /> */}
        {/* <Route path="/devices/videoPlayers" component={VideoPlayers} /> */}
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
