import React, { useState, useEffect } from 'react';
import { Box, InputBase, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './SidebarHeader.style';
import Popover from '@material-ui/core/Popover';
import ProfileDetail from './ProfileDetail';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DestinationUpdateMethods,
  StoragesMethods,
  TransferServicesMethods,
  DestinationLookupListMethod,
} from '../../../../services/auth';
import {
  CurrentDestinationUpdateMethods,
  CurrentStoragesMethod,
  CurrentTransferServicesMethod,
  CurrentDestinationLookupMethod,
} from '../../../../@jumbo/constants/AppConstants';

const SidebarHeader = ({ searchText, setSearchText, method = CurrentDestinationUpdateMethods }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState('Online');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatusColor = () => {
    switch (userStatus) {
      case 'Online':
        return '#8DCD03';
      case 'Busy':
        return '#FF8C00';
      case "Don't Disturb":
        return '#E00930';
      default:
        return '#C1C1C1';
    }
  };
  const open = Boolean(anchorEl);
  const classes = useStyles({ statusColor: getStatusColor() });
  const id = open ? 'user-popover' : undefined;
  const dispatch = useDispatch();
  const history = useHistory();
  const [addDestination, setAddDestination] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState('');
  const [transcodingTemplate, setTranscodingTemplate] = useState('default');
  const [transcoding, setTranscoding] = useState(false);
  const [transcription, setTranscription] = useState(false);
  const [recognization, setRecognization] = useState(false);
  const [storageId, setStorageId] = useState('');
  const [transferMethodId, setTransferMethodId] = useState('');

  const handleClickOpen = () => {
    setAddDestination(true);
  };
  const addDestinationClose = () => {
    setAddDestination(false);
  };
  const token = localStorage.getItem('token');
  const submitAddDestination = () => {
    const data = {
      name: name,
      type: type,
      image: image,
      transcodingTemplate: transcodingTemplate,
      transcoding: transcoding,
      transcription: transcription,
      recognization: recognization,
      storageId: storageId,
      transferMethodId: transferMethodId,
    };
    dispatch(DestinationUpdateMethods[method].OnDestinationUpdate(token, data));
    setAddDestination(false);
    history.push('/destinations');
  };

  const storagesData = useSelector(state => state.auth.getstoragesList);
  const TransferServicesData = useSelector(state => state.auth.gettransferservicesList);
  const destinationLookupData = useSelector(state => state.auth.getDestinationLookupList);

  useEffect(() => {
    dispatch(StoragesMethods[CurrentStoragesMethod].onStoragesList(token));
    dispatch(TransferServicesMethods[CurrentTransferServicesMethod].onTransferServicesList(token));
    dispatch(DestinationLookupListMethod[CurrentDestinationLookupMethod].onDestinationLookupList(token));
  }, []);
  console.log(' My Storages', storagesData);
  console.log(' My transfer', TransferServicesData);
  console.log(' My dest lookup', destinationLookupData);

  const handleImageChange = event => {
    setType(event.target.value);
    destinationLookupData.forEach((item, index) => {
      if (event.target.value == item.name) {
        setImage(item.image);
      }
    });
  };

  return (
    <Box className={classes.sidebarHeaderRoot}>
      <Box className={classes.userRoot}>
        {/* <Box onClick={handleClick} className={classes.avatarRoot}>
          <CmtAvatar size={56} src={'https://via.placeholder.com/150x150'} />
          <Box className={classes.statusDot} />
        </Box> */}
        <Box className={clsx(classes.userInfo, 'Cmt-user-info')}>
          <Typography className={classes.userTitle} component="h3" variant="h6">
            Destinations
          </Typography>
          {/* <Typography className={classes.userSubTitle} component="span">
            Life is short, enjoy it well
          </Typography> */}
        </Box>
        <Box>
          <IconButton aria-label="Add" className={classes.margin} onClick={handleClickOpen}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.searchRoot}>
        <InputBase
          placeholder={'Search here...'}
          inputProps={{ 'aria-label': 'search' }}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <SearchIcon />
      </Box>
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addDestinationClose}
          aria-labelledby="customized-dialog-title"
          open={addDestination}>
          <DialogTitle id="customized-dialog-title" onClose={addDestinationClose}>
            Add Destination
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => setName(event.target.value)}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                    <Select
                      native
                      value={type}
                      onChange={event => handleImageChange(event)}
                      inputProps={{
                        name: 'type',
                        id: 'type-native-simple',
                      }}>
                      <option aria-label="None" value="" />
                      {destinationLookupData &&
                        destinationLookupData.length > 0 &&
                        destinationLookupData.map(dld => <option value={dld.name}>{dld.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <img className={classes.moduleImage} src={image} />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="template-native-simple">TranscodingTemplate</InputLabel>
                    <Select
                      native
                      value={transcodingTemplate}
                      onChange={event => setTranscodingTemplate(event.target.value)}
                      inputProps={{
                        name: 'TranscodingTemplate',
                        id: 'template-native-simple',
                      }}>
                      <option aria-label="None" value="" />
                      <option value="default">Default</option>
                      {/* <option value={20}>False</option> */}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transcoding-native-simple">Transcoding</InputLabel>
                    <Select
                      native
                      value={transcoding}
                      onChange={event => setTranscoding(event.target.value)}
                      inputProps={{
                        name: 'transcoding',
                        id: 'transcoding-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transcription-native-simple">Transcription</InputLabel>
                    <Select
                      native
                      value={transcription}
                      onChange={event => setTranscription(event.target.value)}
                      inputProps={{
                        name: 'transcription',
                        id: 'transcription-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="recognization-native-simple">Recognization</InputLabel>
                    <Select
                      native
                      value={recognization}
                      onChange={event => setRecognization(event.target.value)}
                      inputProps={{
                        name: 'recognization',
                        id: 'recognization-native-simple',
                      }}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="storage-native-simple">Storage Id</InputLabel>
                    <Select
                      native
                      value={storageId}
                      onChange={event => setStorageId(event.target.value)}
                      inputProps={{
                        name: 'storage',
                        id: 'storage-native-simple',
                      }}>
                      <option value="" />
                      {storagesData &&
                        storagesData.length > 0 &&
                        storagesData.map(sts => <option value={sts._id}>{sts.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="transferMethod-native-simple">Transfer Method Id</InputLabel>
                    <Select
                      native
                      value={transferMethodId}
                      onChange={event => setTransferMethodId(event.target.value)}
                      inputProps={{
                        name: 'transferMethod',
                        id: 'transferMethod-native-simple',
                      }}>
                      <option value="" />
                      {TransferServicesData &&
                        TransferServicesData.length > 0 &&
                        TransferServicesData.map(tsd => <option value={tsd._id}>{tsd.name}</option>)}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addDestinationClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddDestination()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default SidebarHeader;
