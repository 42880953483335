/*import Firebase from './firebase';
import JWTAuth from './jwt';*/
import BasicAuth from './Basic';

export const AuhMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  basic: BasicAuth,
};
export const MenusMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  menus: BasicAuth,
};

export const DestinationLookupListMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  destinationLookupList: BasicAuth,
};

export const TransferLookupListMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  transferLookupList: BasicAuth,
};

////////Clients///////

export const ClientMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  clientList: BasicAuth,
};
export const ClientViewDataMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  clientListView: BasicAuth,
};

export const ClientUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  clientUpdate: BasicAuth,
};

export const ClientDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  clientDelete: BasicAuth,
};

export const AwsDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  AwsDelete: BasicAuth,
};

export const IconikDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  IconikDelete: BasicAuth,
};

export const ClientAwsCreateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  ClientAwsCreate: BasicAuth,
};

export const ClientAwsUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  ClientAwsUpdate: BasicAuth,
};

export const IconikCreateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  IconikCreate: BasicAuth,
};

export const IconikUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  IconikUpdate: BasicAuth,
};

export const AsperaCreateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  AsperaCreate: BasicAuth,
};

export const AsperaDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  AsperaDelete: BasicAuth,
};

export const AsperaUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  AsperaUpdate: BasicAuth,
};

export const TenantMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  tenantList: BasicAuth,
};

export const TenantConfigMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  tenantConfig: BasicAuth,
};

/////////Destinations///////

export const DestinationMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  destinationList: BasicAuth,
};
export const DestinationViewMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  destinationListView: BasicAuth,
};

export const DestinationUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  destinationUpdate: BasicAuth,
};

export const DestinationDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  destinationDelete: BasicAuth,
};
//////////// Storages ///////////////

export const StoragesMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  storagesList: BasicAuth,
};

export const StoragesViewDataMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  storagesListView: BasicAuth,
};

export const StoragesUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  storagesUpdate: BasicAuth,
};

export const StoragesDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  storagesDelete: BasicAuth,
};

//////////// Transfer services ///////////////

export const TransferServicesMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  transferservicesList: BasicAuth,
};

export const TransferServicesViewDataMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  transferservicesListView: BasicAuth,
};

export const TransferservicesUpdateMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  transferservicesUpdate: BasicAuth,
};

export const TransferServicesDeleteMethod = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  transferservicesDelete: BasicAuth,
};
