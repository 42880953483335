import React, { useState } from 'react';
import { Box, InputBase, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './SidebarHeader.style';
import Popover from '@material-ui/core/Popover';
import ProfileDetail from './ProfileDetail';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StoragesUpdateMethods } from '../../../../services/auth';
import { CurrentStoragesUpdateMethods } from '../../../../@jumbo/constants/AppConstants';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';

const SidebarHeader = ({ searchText, setSearchText, method = CurrentStoragesUpdateMethods }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState('Online');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatusColor = () => {
    switch (userStatus) {
      case 'Online':
        return '#8DCD03';
      case 'Busy':
        return '#FF8C00';
      case "Don't Disturb":
        return '#E00930';
      default:
        return '#C1C1C1';
    }
  };
  const open = Boolean(anchorEl);
  const classes = useStyles({ statusColor: getStatusColor() });
  const id = open ? 'user-popover' : undefined;
  const dispatch = useDispatch();
  const history = useHistory();
  const [addStorage, setAddStorage] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [bucket, setBucket] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [accessKey, setAccessKey] = useState('');
  const [region, setRegion] = useState('');

  const [nameError, setnameError] = useState('');
  const [bucketError, setBucketError] = useState('');
  const [secretKeyError, setSecretKeyError] = useState('');
  const [accessKeyError, setAccessKeyError] = useState('');
  const [regionError, setRegionError] = useState('');

  const handleClickOpen = () => {
    setAddStorage(true);
  };
  const addStorageClose = () => {
    setAddStorage(false);
  };
  const token = localStorage.getItem('token');

  const createValidation = () => {
    if (!name) {
      setnameError(requiredMessage);
    } else if (!bucket) {
      setBucketError(requiredMessage);
    } else if (!secretKey) {
      setSecretKeyError(requiredMessage);
    } else if (!accessKey) {
      setAccessKeyError(requiredMessage);
    } else if (!region) {
      setRegionError(requiredMessage);
    } else return true;
  };

  const submitAddStorage = () => {
    if (createValidation()) {
      const data = {
        name: name,
        image: image,
        bucket: bucket,
        secretKey: secretKey,
        accessKey: accessKey,
        region: region,
      };
      dispatch(StoragesUpdateMethods[method].OnStoragesUpdate(token, data));
      setAddStorage(false);
      history.push('/storages');
    }
  };
  return (
    <Box className={classes.sidebarHeaderRoot}>
      <Box className={classes.userRoot}>
        {/* <Box onClick={handleClick} className={classes.avatarRoot}>
          <CmtAvatar size={56} src={'https://via.placeholder.com/150x150'} />
          <Box className={classes.statusDot} />
        </Box> */}
        <Box className={clsx(classes.userInfo, 'Cmt-user-info')}>
          <Typography className={classes.userTitle} component="h3" variant="h6">
            Storage List
          </Typography>
          {/* <Typography className={classes.userSubTitle} component="span">
            Life is short, enjoy it well
          </Typography> */}
        </Box>
        <Box>
          <IconButton aria-label="Add" className={classes.margin} onClick={handleClickOpen}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.searchRoot}>
        <InputBase
          placeholder={'Search here...'}
          inputProps={{ 'aria-label': 'search' }}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <SearchIcon />
      </Box>

      <Box className={classes.userRoot} />
      <Box>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={addStorageClose}
          aria-labelledby="customized-dialog-title"
          open={addStorage}>
          <DialogTitle id="customized-dialog-title" onClose={addStorageClose}>
            Add Storage
          </DialogTitle>
          <DialogContent dividers>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.name"
                      label="Name"
                      onChange={event => {
                        setName(event.target.value);
                        setnameError('');
                      }}
                      helperText={nameError}
                      defaultValue={name}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="appModule.image"
                      label="Image"
                      onChange={event => setImage(event.target.value)}
                      defaultValue={image}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.bucket"
                      label="Bucket"
                      onChange={event => {
                        setBucket(event.target.value);
                        setBucketError('');
                      }}
                      helperText={bucketError}
                      defaultValue={bucket}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.secret"
                      label="Secret Key"
                      onChange={event => {
                        setSecretKey(event.target.value);
                        setSecretKeyError('');
                      }}
                      helperText={secretKeyError}
                      defaultValue={secretKey}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.access"
                      label="Access Key"
                      onChange={event => {
                        setAccessKey(event.target.value);
                        setAccessKeyError('');
                      }}
                      helperText={accessKeyError}
                      defaultValue={accessKey}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className=""
                      id="appModule.region"
                      label="Region"
                      onChange={event => {
                        setRegion(event.target.value);
                        setRegionError('');
                      }}
                      helperText={regionError}
                      defaultValue={region}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={addStorageClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => submitAddStorage()} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default SidebarHeader;
