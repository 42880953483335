import React, { useContext, useEffect, useState } from 'react';
import { Box, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
// import { getConversation } from '../../../redux/actions/Chat';
import Conversation from './Conversation';
import ContentHeader from './ContentHeader';
import Typography from '@material-ui/core/Typography';
import CmtImage from '../../../@coremat/CmtImage';
import ChatFooter from './ChatFooter';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getChatContainerHeight } from '../../../@jumbo/constants/AppConstants';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import EditCenteredTabs from './EditTabs/EditCenteredTabs';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { NavLink, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '95%',
  },
  button: {
    margin: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

// Name
// Host
// User
// Password
// Path
// SSH port
// NodeAPI

const CreateAsperaContainer = ({ FormData }) => {
  const dispatch = useDispatch();
  let scrollBarRef = null;

  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [host, setHost] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [path, setPath] = useState('');
  const [sshPort, setSSHport] = useState('');
  const [nodeAPI, setNodeApi] = useState('');

  const token = localStorage.getItem('token');
  const onSubmit = () => {
    const data = {
      _id: FormData._id,
      name: name,
      //email: email,
      //address: address,
      //phone: phone,
    };
    //dispatch(ClientUpdateMethods[method].OnClientUpdate(token, data));
    //history.push('/clients/' + FormData._id + '/isView');
  };

  return (
    <Box className="submain">
      {/* <ContentHeader FormDataToHeader={FormData} /> */}
      <h3>Create Aspera</h3>
      <form className={classes.root} noValidate autoComplete="off">
        <GridContainer>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.appid"
                  label="Name"
                  onChange={event => setName(event.target.value)}
                  defaultValue={name}
                />
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.Host"
                  label="Host"
                  // type="number"
                  onChange={event => setHost(event.target.value)}
                  defaultValue={host}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.User"
                  label="User"
                  // type="number"
                  onChange={event => setUser(event.target.value)}
                  defaultValue={user}
                />
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.password"
                  label="password"
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.path"
                  label="Path"
                  onChange={event => setPath(event.target.value)}
                  defaultValue={path}
                />
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.sshPort"
                  label="SSHport"
                  onChange={event => setSSHport(event.target.value)}
                  defaultValue={sshPort}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className=""
                  id="appModule.NodeAPI"
                  label="NodeAPI"
                  onChange={event => setNodeApi(event.target.value)}
                  defaultValue={nodeAPI}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <Box>
                <Button onClick={onSubmit} variant="contained" className={classes.button} color="primary">
                  {' '}
                  Create{' '}
                </Button>
                <NavLink className={classes.navLink} to={'/clients/' + FormData._id + '/isView'}>
                  <Button variant="contained" className={classes.button} color="secondary">
                    {' '}
                    Cancel{' '}
                  </Button>
                </NavLink>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
        <div />
        <div />
      </form>
    </Box>
  );
};

export default withWidth()(CreateAsperaContainer);
