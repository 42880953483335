import React, { useEffect } from 'react';
import { ClientMethods } from '../../../../services/auth';
import CmtCard from '../../../../@coremat/CmtCard';
import { CurrentClientMethod } from '../../../../@jumbo/constants/AppConstants';
import { Box } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
// import { modernWidget } from '../../../../@fake-db/widgets/modern';

const useStyles = makeStyles(() => ({
  cardRoot: {
    height: '100%',
    minHeight: 90,
  },
  userBoxRoot: {
    width: 85,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '20px',
    overflow: 'hidden',

    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
}));

const TransferServicesHeader = ({ method = CurrentClientMethod }) => {
  const classes = useStyles();
  // const { userSummary } = modernWidget;
  // const dispatch = useDispatch();
  // const ClientData = useSelector(state => state);
  // console.log("ClientData", ClientData)

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   dispatch(ClientMethods[method].onClientList(token));
  // }, []);

  return (
    <CmtCard className={classes.cardRoot}>
      <Box display="flex" alignItems="center" height={1}>
        <Box className={classes.userBoxRoot}>
          {/* <CmtAvatar size={75} src={userSummary.profile_pic} /> */}
          <CmtAvatar size={75} src={'profile_pic'} />
        </Box>
        <Box ml={{ xs: 5, xl: 8 }}>
          <Typography component="div" variant="h4">
            {'TransferServices 1'}
            <br />
            {/* {'1st-Feb-2021'} */}
          </Typography>
        </Box>
      </Box>
    </CmtCard>
  );
};

export default TransferServicesHeader;
